import gql from "graphql-tag";

export default gql`
  mutation CreateProject($name: String!) {
    createProject(
        input: {
            name: $name
        }
    ){
      id
      name
      state
      slug
      createdAt
      updatedAt
      finishedAt
      audiosNumber
      audiosNumberConfirmed
    }
  }
`;