import { gql } from '@apollo/client';

export default gql`
  query {
    GetProjects {
        id
        name
        state
        slug
        createdAt
        updatedAt
        finishedAt
        audiosNumber
        audiosNumberConfirmed
    }
  }
`;
