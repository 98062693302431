import React, { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import { motion } from "framer-motion";
import { printDuration, normalizedPeaks } from "../services/SongsServices";
import { Play, Pause, Info } from "react-feather";
import VolumeSlider from "./VolumeSlider";

export default function AudioPlayer({ currentSong, infoPanel, setInfoPanel }) {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [playing, setPlay] = useState(null);
  const [currentTime, setCurrentTime] = useState("0:00");
  const [remainTime, setRemainTime] = useState("0:00");
  const [audioIsLoading, setAudioIsLoading] = useState(false);

  const wrapStyled = {
    backgroundImage: "linear-gradient(180deg, #2f3234, #0f1010d4)",
    backdropFilter: "blur(5px)",
    paddingRight: 140,
  };

  useEffect(() => {
    const options = {
      container: waveformRef.current,
      waveColor: "#484848",
      progressColor: "white",
      cursorColor: "transparent",
      barWidth: 1,
      barRadius: 8,
      responsive: true,
      height: 70,
      normalize: true,
      pixelRatio: 10,
      barGap: 3,
      hideScrollbar: true,
      backend: "MediaElement",
    };
    wavesurfer.current = WaveSurfer.create(options);
    currentSong &&
      wavesurfer.current.load(
        `https://musichall-api.jbcmusic.es/audio/${currentSong.slugUrl}`,
        normalizedPeaks(JSON.parse(currentSong.Waveform))
      );
    setRemainTime(`-${printDuration(currentSong.AudioDuracion)}`);
    wavesurfer.current.on("loading", (percents) => {
      setAudioIsLoading(true);
    });
    wavesurfer.current.on("ready", function () {
      setAudioIsLoading(false);
      wavesurfer.current.play();
      wavesurfer.current.setVolume(0.5);
      wavesurfer.current.on("play", () => setPlay(true));
      wavesurfer.current.on("pause", () => setPlay(false));
      wavesurfer.current.on(
        "seek",
        () => wavesurfer.current.isPlaying() && wavesurfer.current.play()
      );
      wavesurfer.current.on("finish", () => setPlay(false));
      wavesurfer.current.on("audioprocess", (position) => {
        setCurrentTime(printDuration(Math.floor(position)));
        setRemainTime(
          `-${printDuration(wavesurfer.current.getDuration() - position)}`
        );
      });
    });
    return () => wavesurfer.current.destroy();
  }, [currentSong]);

  const handlePlayPause = () => {
    wavesurfer.current.playPause();
    setPlay(!playing);
  };

  const onVolumeChange = (event) =>
    wavesurfer.current.setVolume(event.target.value);

  const toggleMute = () => wavesurfer.current.toggleMute();

  return (
    <motion.div
      initial={{ y: "100%" }}
      animate={{ y: 0 }}
      exit={{ y: "100%" }}
      style={wrapStyled}
      transition={{ type: "spring", damping: 20 }}
      className="fixed z-55 flex items-center space-x-8 h-28 bottom-0 left-0 right-0 pr-5 pl-8 border-t-4 border-gray-800"
    >
      {!playing && !audioIsLoading ? (
        <button className="hover:opacity-80" onClick={handlePlayPause}>
          <Play size={35} strokeWidth={1} stroke="white" />
        </button>
      ) : (
        <button
          className="hover:opacity-80"
          onClick={handlePlayPause}
          disabled={audioIsLoading}
        >
          <Pause size={35} strokeWidth={1} stroke="cyan" />
        </button>
      )}
      <button
        className="hover:opacity-80"
        onClick={() => setInfoPanel(!infoPanel)}
      >
        <Info
          size={24}
          strokeWidth={infoPanel ? 2 : 1}
          stroke={infoPanel ? `cyan` : `white`}
        />
      </button>
      <time>{currentTime}</time>
      <div
        className={`w-full ${audioIsLoading && `pointer-events-none`}`}
        ref={waveformRef}
      />
      <time>{remainTime}</time>
      <VolumeSlider onVolumeChange={onVolumeChange} toggleMute={toggleMute} />
    </motion.div>
  );
}
