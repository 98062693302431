import gql from "graphql-tag";

export default gql`
  mutation FinishProject($songs: String!, $id: ID!) {
    finishProject(
        input: {
            id: $id
            songs: $songs
        }
    ){
      id
        name
        state
        slug
        createdAt
        updatedAt
        finishedAt
        audiosNumber
        audiosNumberConfirmed
        audios {
            ID
            AudioNombre
            AudioInterprete
            AudioPeso
            AudioDuracion
            AudioGeneros_
            AudioTempo_
            AudioAnimo_
            AudioInstrumento_
            AudioIdioma_
            AudioUploader
            AudioVoz_
            AudioUrl
            slugUrl
            esFavorito
            Waveform 
            isConfirmed
        }
    }
  }
`;