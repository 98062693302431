import { gql } from '@apollo/client';

export default gql`
  query AudioFavourites{
    AudioFavourite {
      ID
      AudioNombre
      AudioInterprete
      AudioCompositor
      AudioProductor_
      AudioISRC
      AudioISWC
      AudioPeso
      AudioDuracion
      AudioGeneros_
      AudioTempo_
      AudioAnimo_
      AudioInstrumento_
      AudioIdioma_
      AudioUploader
      AudioVoz_
      AudioUrl
      esFavorito
      slugUrl
      Waveform 
    }
  }
`;
