import { css } from 'twin.macro'
import BarLoader from "react-spinners/BarLoader";
import ScaleLoader from "react-spinners/ScaleLoader";

const barOverrides = css`
  position: fixed;
  top: 0;
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 2px;
  background-color: transparent;
  z-index: 99999;
`

const scaleOverrides = css`
  width: 100px;
  div {
    height: 50px;
    width: 1.5px;
  }
`

export default function Spinner({loading, wave}) {
  if (wave) {
    return (
      <ScaleLoader
        css={scaleOverrides}
        color="#484848"
        loading={loading}
      />
    )
  }  
  return (
    <BarLoader 
      css={barOverrides}
      color={`cyan`}
      loading={loading}
    />
  )
}