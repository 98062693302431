import axios from "axios"

export const printDuration = (input) => {
  let minutes = Math.floor(input / 60);
  let seconds = Math.floor(input) % 60;
  return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
}

export const TransformData = (objectsArray) => {
  const genres = [];
  const themes = [];
  const instruments = [];
  const tempos = [];
  const voices = [];
  const languages = [];
  objectsArray.forEach((el) => {
    switch (el.type) {
      case "Genero":
        genres.push(parseInt(el.id));
        break;
      case "Animo":
        themes.push(parseInt(el.id));
        break;
      case "Instrumento":
        instruments.push(parseInt(el.id));
        break;
      case "Idioma":
        languages.push(parseInt(el.id));
        break;
      case "Tempo":
        tempos.push(parseInt(el.id));
        break;
      case 'Voz':
        voices.push(parseInt(el.id));
        break;
      default:
        console.log("Error");
    }
  });
  return {
    genres: genres,
    themes: themes,
    instruments: instruments,
    tempos: tempos,
    languages: languages,
    voices: voices
  };
};

export const filterProjects = (projects) => {
  // OJO
  const inProgressProjects = projects && projects.filter((project) => (project && project.state === 0))
  const finalizedProjects = projects && projects.filter((project) => (project && project.state === 1))
  return [inProgressProjects, finalizedProjects]
};

export const downloadAllSongs = (projectId, token, fileName, setLoadingZip, toast, finalized) => {
  const requestOptions = {
    method: "post",
    url: "https://musichall-api.jbcmusic.es/api/getProjectSongs",
    responseType: 'arraybuffer',
    headers: {
      authorization: `Bearer ${token}`,
    },
    data: { token, projectId, finalized }
  };

  axios(requestOptions).then(res => {
    let blob = new Blob([res.data], { type: 'application/zip' })
    const downloadUrl = URL.createObjectURL(blob)
    let a = document.createElement("a"); 
    a.href = downloadUrl;
    a.download = "JBC-" + fileName;
    document.body.appendChild(a);
    a.click();
    setLoadingZip(false)
  })
};

export const downloadSong = (fileName, id, token, projectId, callback) => {
  const requestOptions = {
    method: "post",
    url: "https://musichall-api.jbcmusic.es/api/getSong",
    responseType: 'arraybuffer',
    headers: {
      authorization: `Bearer ${token}`,
    },
    data: {
      fileName: fileName,
      songId: id,
      projectId: projectId
    }
  };

  axios(requestOptions).then(res => {
    // AL INICIAR SESIÓN NO PILLA EL TOKEN BIEN, PILLA UNDEFINED
    let blob = new Blob([res.data], { type: 'audio/mp3' })
    const downloadUrl = URL.createObjectURL(blob)
    let a = document.createElement("a"); 
    a.href = downloadUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    callback()
  })
}

export const normalizedPeaks = (peaks) => {
  if (peaks) {
    let max = peaks.reduce((max, el) => (el > max ? el : max));
    return peaks.map(el => el / max);
  }
}
