import { gql } from '@apollo/client';

export default gql`
  query GetProjectBySlug($slug: String!) {
    ProjectBySlug(slug: $slug) {
        id
        name
        state
        slug
        createdAt
        updatedAt
        finishedAt
        audiosNumber
        audiosNumberConfirmed
        audios {
          ID
          AudioNombre
          AudioInterprete
          AudioCompositor
          AudioProductor_
          AudioISRC
          AudioISWC
          AudioPeso
          AudioDuracion
          AudioGeneros_
          AudioTempo_
          AudioAnimo_
          AudioInstrumento_
          AudioIdioma_
          AudioUploader
          AudioVoz_
          AudioUrl
          esFavorito
          isDownload
          slugUrl
          Waveform 
          isConfirmed
          isDownload
        }
    }
  }
`;
