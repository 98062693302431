import React from "react";
import { Route, Redirect } from "react-router-dom";

import { authenticationService } from "./AuthenticationService";

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const currentUser = authenticationService.currentUserValue;
      if (!currentUser) {
        return <Component {...props} />;
      }
      return (
        <Redirect
          to={{ pathname: "/" }}
        /> 
      )
    }}
  />
);

export default AuthRoute;
