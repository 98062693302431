import React from "react";
import { Route, Redirect } from "react-router-dom"
import Layout from "../contexts/Layout"
import ProjectsProvider from "../contexts/ProjectsContext"

import { authenticationService } from "./AuthenticationService";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const currentUser = authenticationService.currentUserValue;
      if (!currentUser) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }
      return (
        <ProjectsProvider>
          <Layout location={props.location}>
              <Component {...props} />
          </Layout>
        </ProjectsProvider>
      );
    }}
  />
);

export default PrivateRoute;
