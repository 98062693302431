import React, { useContext } from 'react'
import { NavLink } from "react-router-dom";
import { LayoutContext } from '../contexts/Layout'
import { motion } from 'framer-motion'
import Logo from '../components/Logo'

export default function Navigation() {
  const ICON_SIZE = 24
  const { inputSearchIsFocus, setInputSearchIsFocus } = useContext(LayoutContext)
  return (
    <div className="fixed top-0 left-0 bottom-0 w-16 bg-black px-3 py-7 flex flex-col space-y-2 z-50">
      <div className="block mb-2">
        <Logo />
      </div>
      <NavLink to="" onClick={() => setInputSearchIsFocus(true)} className="transition duration-500 p-2 rounded-full relative hover:text-cyan-400 bg-white bg-opacity-0 hover:bg-opacity-5">
        {inputSearchIsFocus && <span className="absolute w-1 h-1 top-1 right-1 rounded-full bg-cyan-400" />}
        <motion.svg whileTap={{ scale: 0.8 }} xmlns="http://www.w3.org/2000/svg" width={ICON_SIZE} fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
        </motion.svg>
      </NavLink>
      <NavLink to="/projects" className="transition duration-500 p-2 rounded-full hover:text-cyan-400 bg-white bg-opacity-0 hover:bg-opacity-5" activeClassName="text-cyan-400">
        <motion.svg whileTap={{ scale: 0.8 }} xmlns="http://www.w3.org/2000/svg" width={ICON_SIZE} fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
        </motion.svg>
      </NavLink>
      <NavLink to="/favourites" className="transition duration-500 p-2 rounded-full hover:text-cyan-400 bg-white bg-opacity-0 hover:bg-opacity-5" activeClassName="text-cyan-400">
        <motion.svg whileTap={{ scale: 0.8 }} xmlns="http://www.w3.org/2000/svg" width={ICON_SIZE} fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
        </motion.svg>
      </NavLink>
      <NavLink to="/user" className="transition duration-500 p-2 rounded-full hover:text-cyan-400 bg-white bg-opacity-0 hover:bg-opacity-5" activeClassName="text-cyan-400">
        <motion.svg whileTap={{ scale: 0.8 }} xmlns="http://www.w3.org/2000/svg" width={ICON_SIZE} fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
        </motion.svg>
      </NavLink>
    </div>
  )
}