import { gql } from '@apollo/client'

export default gql`
  query {
    GetTerms {
      id
      position
      editable
      key_
      name
      type
      value
    }
  }
`
