import React, { useState, useEffect, useContext, useRef, createRef } from "react"
import { useParams, useHistory } from "react-router-dom"
import { useQuery, useMutation } from "@apollo/client"
import GetProject from "../graphql/queries/GetProject"
import Song, { SongSkeleton } from "../components/Song"
import { downloadAllSongs, printDuration } from "../services/SongsServices"
import { authenticationService } from "../services/AuthenticationService"
import ProjectFinishModal from './ProjectFinishModal'
import { ProjectsContext } from '../contexts/ProjectsContext'
import { LayoutContext } from '../contexts/Layout'
import UpdateProjectTitle from "../graphql/mutations/UpdateProjectTitle"
import { AnimatePresence, motion } from 'framer-motion'

export default function ProjectInfo() {
  const { slug } = useParams();
  const history = useHistory()
  const [ project, setProject ] = useState(null);
  const [ isFinalized, setIsFinalized ] = useState(false);
  const [ titleEditable, setTitleEditable ] = useState(false)
  const [ audios, setAudios ] = useState([])
  const [ loadingZip, setLoadingZip ] = useState(false)
  const [ finalizeModal, setFinalizeModal ] = useState(false)
  const elRefs = useRef([])
  const inputTitleRef = useRef(null)
  const { projects: ctxProjects, setProjects: SetCtxProjects } = useContext(ProjectsContext)
  const { currentSong, ToastsStore, setLoader } = useContext(LayoutContext)
  const { loading, data, error } = useQuery(GetProject, {
    variables: { slug: slug },
    onCompleted() {
      if (data.ProjectBySlug) {
        setProject(data.ProjectBySlug);
      }
    },
    fetchPolicy: "network-only"
  })
  // eslint-disable-next-line
  const [updateName, { data: dataMutation, loading: loadingMutation, error: errorMutation }] = useMutation(UpdateProjectTitle)
  useEffect(() => {
    if (project) {
      setIsFinalized(project.state === 1 ? true : false)
      setAudios(project.state === 1 ? project.audios.filter(audio => (audio.isConfirmed === 1)) : project.audios)
    }
  }, [project])

  useEffect(() => {
    if (dataMutation) {
      history.push(`/project/${dataMutation.updateName.slug}`)
      setTitleEditable(false)
    }
  }, [dataMutation, history])

  const exitInput = (e) => {
    if (e.target.id !== "inputTitle") {
      setTitleEditable(false)
      document.removeEventListener('keydown', listenerClick)
      document.removeEventListener('mousedown', exitInput)
    }
  }

  const editable = () => {
    setTitleEditable(true)
    document.addEventListener('keydown', listenerClick)
    document.addEventListener('mousedown', exitInput)
  }

  useEffect(() => {
    setLoader(loading)
    // eslint-disable-next-line
  }, [loading])
  
  if (audios && elRefs.current.length !== audios.length) {
    elRefs.current = Array(audios.length).fill().map((_, i) => elRefs.current[i] || createRef())
  }
  
  const totalMinutes = (confirmed) => {
    let durations = []
    let sum = 0
    project.audios.forEach(audio => {
      if (confirmed) {
        if (audio.isConfirmed === 1) {
          durations.push(audio.AudioDuracion)
        }
      } else {
        durations.push(audio.AudioDuracion)
      }
    })
    for(var i=0; i < durations.length; i++){
      sum += parseInt(durations[i]);
    }
    return sum
  }
  
  const deleteSongInList = (id) => {
    setAudios(audios.filter(audio => audio.ID !== id))
    let project_ = null
    let projectName_ =  ''
    let projects = ctxProjects.filter(pr => {
      if (pr.id !== project.id) {
        return pr
      } else {
        project_ = {...pr, audiosNumber: pr.audiosNumber - 1}
        projectName_ = pr.name
        return null
      }
    })
    ToastsStore.success(`Removed from "${projectName_}"`)
    SetCtxProjects(projects.concat([project_]))
  }

  const saveTitle = (ntitle) => {
    updateName({variables: {
      id: project.id,
      title: ntitle
    }}).catch(err => {
      if (err.graphQLErrors[0].extensions.code === "BAD_USER_INPUT") {
        ToastsStore.warning("The project title already exists.")
        setTitleEditable(false)
      } else {
        ToastsStore.success("An error has occurred. please contact your system administrator.")
      }
    }) 
  }

  const listenerClick = (event) => {
    if (event.key === "Enter") {
      let titleInput = document.getElementById("inputTitle")
      if (titleInput.value !== "" && titleInput.value !== project.name) {
        saveTitle(titleInput.value)
        document.removeEventListener("keydown", listenerClick)
        document.removeEventListener('mousedown', exitInput)
      } else {
        document.removeEventListener("keydown", listenerClick)
        document.removeEventListener('mousedown', exitInput)
        setTitleEditable(false)
      }
    }
    if (event.key === "Escape") {
      document.removeEventListener("keydown", listenerClick)
      document.addEventListener('mousedown', exitInput)
      setTitleEditable(false)
    }
  }
  if (error) {
    authenticationService.logout()
  }

  return (
    <AnimatePresence>
      {loading ? <ProjectInfoSkeleton key={loading} /> : project ? (
        <>
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="relative z-20 w-full"
        >
          <div className="fixed left-16 z-10 px-10 -mt-12 w-1/2 top-24">
            {(titleEditable && !isFinalized) ? 
              <input id="inputTitle" ref={inputTitleRef} className="block text-6xl font-bold mb-1 bg-transparent p-0 m-0 outline-none h-16 animate-pulse pb-2 underline" autoFocus name="title" defaultValue={project.name} maxlength="100" type="text" />
              : 
              <h1 className="text-6xl font-bold mb-1 h-16 hover:text-gray-300 whitespace-nowrap" onClick={editable}>{project.name}</h1>
            }
            <span>{`${isFinalized ? project.audiosNumberConfirmed : project.audiosNumber} song${project.audiosNumber !== 1 ? `s` : ``}`}, {project && printDuration(totalMinutes(isFinalized)).split(':')[0]} minutes</span>
            <div className="flex items-center space-x-3 mt-3">
              <motion.button whileTap={{ scale: 0.98 }} className={`flex space-x-3 items-center rounded py-3 font-bold hover:bg-cyan-300 text-gray-900 transitions duration-200 whitespace-nowrap ${isFinalized ? `bg-white pointer-events-none rounded-full px-3` : `bg-cyan-400 px-5`} ${!audios.length && !isFinalized && `bg-cyan-800  pointer-events-none`}`} onClick={()=>setFinalizeModal(true)}>
                {isFinalized ? 
                  <svg xmlns="http://www.w3.org/2000/svg" width={24} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2.5} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                  </svg>
                : 
                  <svg xmlns="http://www.w3.org/2000/svg" width={24} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2.5} d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z" />
                  </svg>
                }
                {isFinalized ? null : <span>Finalize project</span>}
              </motion.button>
              <motion.button
                whileTap={{ scale: 0.98 }}
                className={`rounded-full p-3 bg-gray-600 hover:bg-gray-500 ${(isFinalized || !audios.length) && `text-gray-500 pointer-events-none`}`}
                onClick={() => {
                  if (!loadingZip) {
                    ToastsStore.success("Preparing zip download")
                    setLoadingZip(true)
                    downloadAllSongs(
                      project.id,
                      authenticationService.currentUserValue.token,
                      project.slug,
                      setLoadingZip,
                      ToastsStore,
                      isFinalized
                    )
                  }
                }}
              >
                {!loadingZip ? 
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                    </svg>
                  :
                    <svg className="" width="24" height="24" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff">
                      <g fill="none" fillRule="evenodd">
                        <g transform="translate(1 1)" strokeWidth="2">
                          <circle strokeOpacity=".5" cx="18" cy="18" r="18"/>
                          <path stroke="white" d="M36 18c0-9.94-8.06-18-18-18">
                            <animateTransform
                              attributeName="transform"
                              type="rotate"
                              from="0 18 18"
                              to="360 18 18"
                              dur="1s"
                              repeatCount="indefinite"/>
                          </path>
                        </g>
                      </g>
                    </svg>
                }
              </motion.button>
            </div>
          </div>
          <div className="fixed left-16 right-0 mt-20 pt-10 overflow-auto scroller" style={{ height: !currentSong ? 'calc(100vh - 11rem)' : 'calc(100vh - 11rem - 112px)' }}>
            {audios &&
              audios.map((audio, i) => 
                <Song 
                  key={audio.ID} 
                  data={audio}
                  index={i} 
                  refs={elRefs}
                  projectID={project.id}
                  deleteSongInList={deleteSongInList}
                  editable={parseInt(project.state)}
                />
              )
            }
          </div>
          <div className="relative -z-10" style={{ zIndex: '-1' }}>
            {!audios.length && <div className="flex justify-center items-center h-64 font-bold px-10 my-10">There are no songs</div>}
          </div>
        </motion.div>
        <AnimatePresence>
          {finalizeModal && <ProjectFinishModal project={project} setProject={setProject} close={()=>setFinalizeModal(false)} />}
        </AnimatePresence>
        </>
      )
      : <span>error</span>
      }
    </AnimatePresence>
  )
}

function ProjectInfoSkeleton() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="absolute z-30 w-full">
        <div className="fixed left-16 z-10 px-10 mb-10 -mt-11">
          <div className="flex space-x-3 mb-2 opacity-40">
            <span className="block h-12 bg-gray-600 rounded-lg animate-pulse" style={{ width: `${Math.floor(Math.random() * 180) + 60 }px` }} />
            <span className="block h-12 bg-gray-600 rounded-lg animate-pulse" style={{ width: `${Math.floor(Math.random() * 80) + 30 }px` }} />
            <span className="block h-12 bg-gray-600 rounded-lg animate-pulse" style={{ width: `${Math.floor(Math.random() * 180) + 50 }px` }} />
          </div>
          <div className="flex space-x-2 opacity-40">
            <span className="block h-4 bg-gray-600 rounded animate-pulse mt-2" style={{ width: `${Math.floor(Math.random() * 20) + 10 }px` }} />
            <span className="block w-16 h-4 bg-gray-600 rounded animate-pulse mt-2" />
          </div>
          <div className="flex space-x-3 mt-5">
            <span className="block w-48 h-12 bg-gray-600 rounded animate-pulse" />
            <span className="block w-12 h-12 bg-gray-600 rounded-full animate-pulse" />
          </div>
        </div>
        <div className="fixed left-16 right-0 pt-28 mt-2">
          <SongSkeleton rows={10} />
        </div>
      </motion.div>
  )
}