import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from "react-router-dom"
import App from './App'
import './index.css'
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import HttpsRedirect from 'react-https-redirect';

ReactDOM.render(
  <HttpsRedirect>
    <Router>
      <App />
    </Router>
  </HttpsRedirect>,
  document.getElementById('app')
);