import React from 'react'
import { NavLink } from "react-router-dom"

export default function Project({ data: project }) {
  const { name, slug, state, audiosNumber } = project
  return (
    <NavLink className="flex items-center justify-between px-10 py-5 hover:text-gray-400" to={`/project/${slug}`}>
      <div className="flex items-center space-x-5">
        {state ? 
          <svg xmlns="http://www.w3.org/2000/svg" width={18} fill="none" viewBox="0 0 24 24" stroke="white">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
          </svg>
        : 
          <svg xmlns="http://www.w3.org/2000/svg" width={18} fill="none" viewBox="0 0 24 24" stroke="white">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z" />
          </svg>
        }
        <span className="text-lg">{name}</span>
      </div>
      <span className="text-white">{`${audiosNumber ? audiosNumber : `0`} song${project.audiosNumber !== 1 ? `s` : ``}`}</span>
    </NavLink>
  )
}