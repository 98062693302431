import React, { useState, useContext, useEffect } from 'react'
import { LayoutContext } from '../contexts/Layout'
import { filterProjects } from "../services/SongsServices"
import { ProjectsContext } from '../contexts/ProjectsContext'
import Project from './Project'
import { motion } from 'framer-motion'

export default function Projects() {
  const { projects: ctxProjects } = useContext(ProjectsContext)
  const { setLoader } = useContext(LayoutContext)
  const [projects, setProjects] = useState(null)
  const [projectsInProgress, setProjectsInProgress] = useState([])
  const [projectsFinalizeds, setProjectsFinalizeds] = useState([])
  const [activeTab, setActiveTab] = useState(1)
  useEffect(() => {
      const res = filterProjects(ctxProjects)
      setProjects(ctxProjects)
      setProjectsInProgress(res[0])
      setProjectsFinalizeds(res[1])
  }, [ctxProjects])

  useEffect(() => {
    setLoader(!projects)
    // eslint-disable-next-line
  }, [projects])

  const tabs = [
    {
      title: 'All',
      contents: projects
    },
    {
      title: 'In Progress',
      contents: projectsInProgress
    },
    {
      title: 'Finalized',
      contents: projectsFinalizeds
    }
  ]

  if (!projects) {
    return null
  }

  return (
    <>
      <div className="fixed left-16 w-full z-30 flex px-10 list-none" style={{ top: 80 }}>
        <motion.li initial={{ opacity: 0, transitionDelay: .2 }} animate={{  opacity: 1 }} exit={{ opacity: 0 }}>
          {tabs.map((tab, index) => (
            <button key={index} className={` py-2 mr-5 border-b border-solid border-transparent hover:border-cyan-400 transition ${index === activeTab ? `border-cyan-400` : null}`} onClick={()=>setActiveTab(index)}>{tab.title}</button>
          ))}
        </motion.li>
      </div>
      <motion.div initial={{ opacity: 0 }} animate={{  opacity: 1 }} exit={{ opacity: 0 }}>
        {projects && !projects.length ? (
          <div className="flex justify-center items-center h-64 font-bold">There are no projects</div>
        ) : 
        (
          <div className="flex flex-col pt-7">
            {tabs.map((tab, index) => (
              <div key={index} style={{ display: index === activeTab ? 'flex' : 'none', flexDirection: 'column' }}>
                  {tab.contents.length ? tab.contents.map(content => <Project key={content.id} data={content} />) : <div className="flex justify-center items-center h-64 font-bold">There are no projects {tab.title.toLowerCase()}</div>}
              </div>
            ))}
          </div>
        )}
      </motion.div>
    </>
  )
}