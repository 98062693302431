import React, { useState, useEffect, useContext } from 'react'
import { useHistory} from "react-router-dom"
import { authenticationService } from "../services/AuthenticationService"
import { useQuery } from "@apollo/client"
import { motion } from 'framer-motion'
import { LayoutContext } from '../contexts/Layout'
import GetUserLogs from "../graphql/queries/GetUserLogs"
import GetUserInfo from "../graphql/queries/GetUserInfo"
import GetTermsAndConditions from "../graphql/queries/GetTermsAndConditions"
import GetTermsAndConditionsFile from "../graphql/queries/GetTermsAndConditionsFile"
import ReactTooltip from 'react-tooltip'

export default function User() {
  const [activeTab, setActiveTab] = useState(0)
  const [user, setUser] = useState(null)
  const [tmpPassword, seTmpPassword] = useState("")
  const [termsAndConditions, setTermsAndConditions] = useState(null)
  const [termsAndConditionsFile, setTermsAndConditionsFile] = useState(null)
  
  const { ToastsStore, setLoader } = useContext(LayoutContext)
  const history = useHistory()
  const [logs, setLogs] = useState([])
  const {loading, error, data} = useQuery(GetUserLogs)
  const {data: terms} = useQuery(GetTermsAndConditions)
  const {data: termsFile} = useQuery(GetTermsAndConditionsFile)
  const {loading: loadingUser, error: errorUser, data: dataUser} = useQuery(GetUserInfo)
  const ICON_SIZE = 14


  useEffect(() => {
    if (terms && terms['GetTerms']) {
      setTermsAndConditions(terms['GetTerms'])
    }
  }, [terms])

  useEffect(() => {
    if (termsFile && termsFile['GetTermsFile']) {
      setTermsAndConditionsFile(termsFile['GetTermsFile'])
    }
  }, [termsFile])


  useEffect(() => {
    setLoader(loadingUser)
    // eslint-disable-next-line
  }, [loadingUser])

  const tabs = [
    {
      title: 'Profile',
      contents: ''
    },
    {
      title: 'Edit',
      contents: ''
    },
    {
      title: 'Terms & Conditions',
      contents: ''
    },
    {
      title: 'Logs',
      contents: ''
    }
  ]

  const logout = () => {
    authenticationService.logout()
    history.push({
      pathname: '/login'
    })
  }

  const onHandleChange = (e) => {
    seTmpPassword(e.target.value)
  }

  const onHandleSubmit = () => {
    const exp = /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])/g
    if (exp.test(tmpPassword)) {
      authenticationService.changePassword(tmpPassword)
    } else {
      ToastsStore.warning("Set a secure password")
    }
  }

  useEffect(() => {
    if (!loading && data && !error) {
      setLogs(data.getUserLogs)
    }
  }, [data, loading, error])

  useEffect(() => {
    if (!loadingUser && dataUser && !errorUser) {
      setUser(dataUser.User)
    }
  }, [loadingUser, dataUser, errorUser])

  const printDate = (time) => {
    return new Date(parseInt(time)).toString()
    
  }
  //Podemos hacer ventana de loading mientras los datos on esten listos

  if (error || errorUser) {
    authenticationService.logout()
    return <span className="pt-10">Componente de error</span>
  }
  if (user && logs) {
    return (
      <>
          <div className="fixed left-16 w-full z-30 flex px-10 list-none" style={{ top: 80 }}>
            <motion.li initial={{ opacity: 0 }} animate={{opacity: 1 }} transition={{ duration: 0.5 }}>
              {tabs.map((tab, index) => (
                <button key={index} className={`py-2 mr-5 border-b border-solid border-transparent hover:border-cyan-400 transition ${index === activeTab ? `border-cyan-400` : null}`} onClick={()=>setActiveTab(index)}>{tab.title}</button>
              ))}
            </motion.li>
          </div>
          <motion.div className="px-10 py-12" initial={{ opacity: 0 }} animate={{  opacity: 1 }} exit={{ opacity: 0 }}>
          <div className="max-w-4xl">
            <div className="space-y-5">
              {activeTab === 0 && (
                <>
                  <div className="grid grid-cols-2 gap-5 p-5 rounded-xl border border-solid border-gray-600">
                    <div>
                      <h6 className="text-xs font-semibold text-gray-600 uppercase">Name</h6>
                      <h1 className="text-2xl font-bold">{user.nombre}</h1>
                    </div>
                    <div>
                      <h6 className="text-xs font-semibold text-gray-600 uppercase">ID</h6>
                      <h2 className="text-2xl font-bold">{user.dni ? user.dni : "N/A"}</h2>
                    </div>
                  </div>
                  <div className="rounded-xl border border-solid border-gray-600 p-5">
                  <button className="px-5 py-3 rounded-lg bg-cyan-400 hover:bg-cyan-500 text-gray-900 font-bold text-lg" onClick={logout}>Logout</button>
                  </div>
                </>
              )}
              {activeTab === 1 && (
                <div className="rounded-xl border border-solid border-gray-600 p-5 flex flex-col items-start">
                  <h2 className="text-xs font-semibold text-gray-600 uppercase mb-3">Change password</h2>
                  <input className="block mb-5 text-lg p-3 border-0 rounded text-black" onChange={onHandleChange} type="password" name="password" />
                  <button className="px-5 py-3 rounded bg-cyan-400 hover:bg-cyan-500 text-gray-900 font-bold text-lg" onClick={onHandleSubmit}>Save</button>
                </div>
              )}
              {activeTab === 2 && (
                <>
                  <div className="rounded-xl border border-solid border-gray-600 p-5">
                    <h2 className="text-xs font-semibold text-gray-600 uppercase mb-3">Terms & Conditions</h2>
                    <div dangerouslySetInnerHTML={{__html: termsAndConditions.value? termsAndConditions.value : ''}}></div>
                  </div>

                  {termsAndConditionsFile && termsAndConditionsFile.value && 
                    <div className='flex'>
                      <a href={'https://musichall-api.jbcmusic.es/'+termsAndConditionsFile.value} target="_blank" download className="rounded-xl border border-solid border-gray-600 px-3 py-2 hover:bg-gray-500 cursor-pointer transition ease-in-out delay-75">
                        Download Terms & Conditions File
                      </a>
                    </div>
                  }

                  <div className="rounded-xl border border-solid border-gray-600 p-5">
                    <h2 className="text-xs font-semibold text-gray-600 uppercase mb-3">Contract</h2>
                    {user.documento ? 
                      <a 
                        className="flex items-center justify-center space-x-2 bg-gray-600 hover:bg-gray-500 rounded-lg px-5 py-3 font-bold" 
                        href={`https://musichall-api.jbcmusic.es/documents/${user.documento}`} target="_blank" rel="noreferrer"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                        </svg>
                        <span>View document</span>
                      </a> 
                      :
                      "No tiene documento asignado"
                    }
                  </div>
                </>
              )}
              {activeTab === 3 && (
                <div className="rounded-xl border border-solid border-gray-600 p-5">
                  <ul className="rounded-xl -m-5">
                    <li className="grid grid-cols-3 gap-10 py-5 border-b border-gray-600 px-5">
                      <span className="text-xs font-semibold text-gray-600 uppercase">Date</span>
                      <span className="text-xs font-semibold text-gray-600 uppercase">IP</span>
                      <span className="text-xs font-semibold text-gray-600 uppercase">Agent</span>
                    </li>
                    {logs.map(log => (
                      <>
                        <li className="grid grid-cols-3 gap-10 py-5 border-b border-gray-600 font-mono text-sm px-5">
                          <span>{printDate(log.datelog).split(' GMT', [1])}</span>
                          <span>{log.iplog.split('::ffff:', [2])}</span>
                          <div className="flex items-center space-x-5" data-tip={log.agent} effect="solid">
                          {/Windows/.test(log.agent) && <svg xmlns="http://www.w3.org/2000/svg" width={ICON_SIZE} fill="currentColor" alt={log.agent} viewBox="0 0 512 512"><path d="M0 80v160h224V52zM256 48v192h256V16zM256 272v192l256 32V272zM0 272v160l224 28V272z"/></svg>}
                          {/Macintosh/.test(log.agent) && <svg xmlns="http://www.w3.org/2000/svg" width={ICON_SIZE} fill="currentColor" viewBox="0 0 448.3 448.3"><path d="M363 279a104 104 0 01-7-60 93 93 0 0148-67c-26-40-68-44-81-45-29-3-69 22-91 22h-1c-21 0-62-25-91-22-14 1-60 9-87 53-10 15-17 34-20 59a216 216 0 001 60 258 258 0 0046 114c24 35 52 55 68 55 32 1 60-21 83-20h2c23-1 51 21 83 20 15 0 43-20 68-55a269 269 0 0033-64c-24-7-44-26-54-50z"/><path d="M288 73c25-29 24-71 22-73-3-2-43 6-69 34a96 96 0 00-21 73c2 2 44-4 68-34z"/></svg>}
                          {/Linux/.test(log.agent) && <svg xmlns="http://www.w3.org/2000/svg" width={ICON_SIZE} fill="currentColor" viewBox="0 0 305 305"><path d="M275 245c-9-4-13-9-13-16 1-8-4-15-6-17 1-5 5-23 0-39-6-17-24-43-42-69-8-10-8-21-8-34-1-13-1-27-8-43-8-17-23-27-42-27-11 0-23 4-32 10-18 12-16 40-14 58v7c1 18 0 27-1 30l-9 13-13 18c-5 8-9 19-13 30l-8 20c-5 9-4 17-3 20l-7 9c-2 6-8 9-19 11-6 1-9 3-11 7-3 4-1 10 0 15 2 6 1 9-1 16l-2 6c-1 3-1 6 1 8 4 7 17 9 30 11 8 1 16 4 24 7l24 7h4c11 0 16-8 18-11l33-4c14 0 28 3 32 4 2 2 5 8 10 11l11 2c5 0 13-1 20-8a174 174 0 0143-27c6-3 10-9 10-14-1-5-4-9-8-11zm-151-1c-1-6-8-12-17-19-7-6-16-12-18-17-5-12-1-31 5-41 4-5 6-13 9-20 2-8 5-16 8-20 5-6 10-16 11-25 4 4 12 10 18 10h3l18-9 15-8c5 6 31 64 33 82 2 15 0 27-1 31h-3c-7 0-9 4-10 6l-1 30c-3 4-16 19-35 22a150 150 0 01-32 1l-9-11c3-2 7-5 6-12zm12-180l-1 1v-2c-1-6-5-11-9-11h-1c-3 1-5 3-6 6 1-7 4-11 9-11 4 0 8 6 8 14v3zm38 5l1-5c0-7-5-12-11-12-5 0-10 5-10 12v1l-1-6c0-8 5-15 12-15 6 0 12 7 12 15 0 4-1 7-3 10zm-5 16l-2 2-5 2-1 1c-5 3-16 10-19 10l-6-3-2-1c-5-3-8-7-9-8l8-7c5-4 9-6 11-6l14 4 5 2 6 4zm36 186a148 148 0 004-48c0 4 1 14 9 17l7 1c8 0 16-3 19-7l5-6v3c0 7 3 16 10 19l1 1c2 1 8 4 8 6l-12 7c-9 4-20 10-24 15-7 7-14 11-19 11h-1c-5-2-9-9-7-19zM40 245l-1-5c1-2 7-3 10-4 4-1 8-1 11-3s5-6 7-10c1-2 2-5 4-6 3 0 9 6 12 11l5 8c5 9 13 22 17 26 3 4 9 11 8 17-1 5-7 9-8 10h-2c-8 0-23-6-31-9l-1-1c-4-2-12-3-19-4l-15-3c-1-2 1-5 2-9l2-7-1-11z"/></svg>}
                          {/Android/.test(log.agent) && <svg xmlns="http://www.w3.org/2000/svg" width={ICON_SIZE} fill="currentColor"><path d="M77 179c-10 0-18 3-24 10-7 7-10 15-10 24v143c0 9 3 18 10 24 6 7 14 10 24 10 9 0 17-3 24-10 6-6 10-15 10-24V213c0-9-4-17-10-24-7-7-15-10-24-10zM352 51l24-44c1-3 1-5-2-6-3-2-5-1-7 2l-24 44a163 163 0 00-133 0L186 3c-2-3-4-4-7-2-3 1-3 3-2 6l24 44c-24 12-43 29-57 51s-21 46-21 71h307c0-25-7-49-21-71s-33-39-57-51zm-136 63c-3 3-6 4-10 4-3 0-6-1-9-4-2-2-3-5-3-9s1-7 3-9c3-3 6-4 9-4s7 1 10 4c2 2 3 5 3 9s-1 7-3 9zm140 0a12 12 0 01-18 0c-3-2-4-5-4-9s1-7 4-9c2-3 5-4 9-4 3 0 6 1 9 4 2 2 3 5 3 9s-1 7-3 9zM124 407c0 10 4 19 11 26s15 10 26 10h24v76c0 9 4 17 10 24s15 10 24 10c10 0 18-3 25-10s10-15 10-24v-76h45v76c0 9 4 17 10 24s15 10 25 10c9 0 17-3 24-10s10-15 10-24v-76h25a35 35 0 0036-37V186H124v222zM476 179c-9 0-17 3-24 10-6 6-10 15-10 24v143a34 34 0 0068 0V213c1-9-3-18-9-24-7-7-15-10-25-10z"/></svg>}
                          {/Iphone/.test(log.agent) && <svg xmlns="http://www.w3.org/2000/svg" width={ICON_SIZE} fill="currentColor" viewBox="0 0 27.4 27.4"><path d="M19 0H8L6 2v23c0 2 1 2 2 2h11c2 0 2 0 2-2V2c0-1 0-2-2-2zm-8 1h6v1c0 1 0 0 0 0h-6V1zm3 24a1 1 0 110-2 1 1 0 010 2zm6-4H7V3h13v18z"/></svg>}
                          {/Ipad/.test(log.agent) && <svg xmlns="http://www.w3.org/2000/svg" width={ICON_SIZE} fill="currentColor" viewBox="0 0 34 34"><path d="M27 0H7L5 2v30l2 2h20l2-2V2l-2-2zM17 33a1 1 0 110-3 1 1 0 010 3zm10-4H7V3h20v26z"/></svg>}
                          {/Firefox/.test(log.agent) && <svg xmlns="http://www.w3.org/2000/svg" width={ICON_SIZE} fill="currentColor" viewBox="0 0 512 512"><path d="M356 11c50 34 78 122 78 189 0 17-2 34-6 50-4-39-24-73-54-96a134 134 0 01-107 213c-30 0-44-5-67-22 46 0 73-44 112-44 0 0-6-23-34-23s-14 23-67 23-77-32-77-56 36-42 44-34c8-7 0-22 0-22l67-44h-22c-99 0-42-70-23-89-35 0-57 32-66 44-6-2-45-2-56 0-5 1-12-7-18-19-8-16-15-36-15-48a75 75 0 00-13 88l-1 1a255 255 0 00225 379c141 0 256-104 256-245v-22c0-132-85-202-156-223z"/></svg>}
                          {/MSIE/.test(log.agent) && <svg xmlns="http://www.w3.org/2000/svg" width={ICON_SIZE} fill="currentColor" viewBox="0 0 512 512"><path d="M35 270C84 178 178 84 270 35A238 238 0 0035 270z"/><path d="M472 141c11-41 12-87-17-117-29-29-80-32-142-9l-43 20c19 0 39 2 59 7 75-27 130-10 118 68-31-33-72-58-118-68A545 545 0 0042 329c10 46 35 87 68 119-72 11-97-37-68-119-5-20-7-40-7-59l-20 43c-23 62-20 113 9 142 30 29 77 28 117 17a238 238 0 00354-109c4-10-4-21-14-21H371c-5 0-9 2-12 6a113 113 0 01-186-23c-1-1-3-8 6-8h316c8 0 14-6 15-14a244 244 0 00-38-162zm-103 90H179c-9 0-7-8-6-9a113 113 0 01202 1c0 2 2 8-6 8z"/></svg>}
                          {/Safari/.test(log.agent) && !/Chrome/.test(log.agent) && <svg xmlns="http://www.w3.org/2000/svg" width={ICON_SIZE} fill="currentColor" viewBox="0 0 512 512"><path d="M256 0a256 256 0 101 513 256 256 0 00-1-513zM96 416l119-178 59 59L96 416zm201-142l-59-59L416 96 297 274z"/></svg>}
                          {/Chrome/.test(log.agent) && <svg xmlns="http://www.w3.org/2000/svg" width={ICON_SIZE} fill="currentColor" viewBox="0 0 305 305"><path d="M96 153a57 57 0 10114-1 57 57 0 00-114 1z"/><path d="M284 77v-2A153 153 0 00153 0C106 0 62 21 33 57v3l43 75a2 2 0 005-1 74 74 0 0178-55h123l2-2z"/><path d="M175 225l-3-1a74 74 0 01-87-40L24 78l-2-2-2 2a153 153 0 00110 225l2-1 43-74v-3z"/><path d="M292 95h-86a2 2 0 00-2 5 74 74 0 019 95l-61 106a3 3 0 002 4 152 152 0 00151-152c0-20-4-38-10-56l-3-2z"/></svg>}
                          </div>
                        </li>
                      </>
                    ))}
                    <ReactTooltip />
                  </ul>
                </div>
              )}
            </div>
          </div>
        </motion.div>
      </>
    )
  }
  return null // loading
}