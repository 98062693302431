import React, { useContext } from 'react'
import { LayoutContext } from '../contexts/Layout'
import SongListPageTest from "./SongListPageTest"
import { motion } from 'framer-motion'

export default function Home() {
  const { inputSearchIsFocus } = useContext(LayoutContext)
  return (
    <motion.div initial={{ opacity: 0 }} animate={{  opacity: 1 }}  transition={{ duration: 0.1 }}>
      {inputSearchIsFocus && <SongListPageTest />}
    </motion.div>
  )
}