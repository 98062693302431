import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: 'https://musichall-api.jbcmusic.es/graphql',
  // uri: 'http://localhost:5001/graphql',
  credentials: 'same-origin'
})

const authLink = setContext((_, { headers }) => {
  const token = JSON.parse(localStorage.getItem('currentUser'))
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token.token}` : ""
    }
  }
})

export const Client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
