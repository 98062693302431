import React, {useEffect, useState, useContext, useRef, createRef } from 'react'
import { useLazyQuery } from "@apollo/client"
import Song, { SongSkeleton } from './Song'
import GetSongs from '../graphql/queries/GetSongs'
import { LayoutContext } from '../contexts/Layout'
import { TransformData } from "../services/SongsServices"

Object.size = function(obj) {
  let cont = 0
  for (const el in obj) {
    cont += obj[el].length
  }
  return cont
}

export default function SongList({ offset, setOffset, maxOffset, setMaxoffset }) {
  const [songs, setSongs] = useState([])
  const { activeFilters, search, setLoader } = useContext(LayoutContext)
  const [myFilters, setMyFilters] = useState({})
  const [mySearch, setMySearch] = useState("")
  const songsLength = songs.length
  const elRefs = useRef([])
  const [getSongs, { loading, error, data}] = useLazyQuery(GetSongs, {
    variables: {
      offset: offset,
      themes: myFilters.themes ? JSON.stringify(myFilters.themes) : "",
      instruments: myFilters.instruments ? JSON.stringify(myFilters.instruments) : "",
      languages: myFilters.languages ? JSON.stringify(myFilters.languages) : "",
      voices: myFilters.voices ? JSON.stringify(myFilters.voices) : "",
      tempos: myFilters.tempos ? JSON.stringify(myFilters.tempos) : "",
      genres: myFilters.genres ? JSON.stringify(myFilters.genres) : "",
      search: search ? search.replaceAll("'", "''") : ""
    },
    fetchPolicy: "network-only"
  })

  useEffect(() => {
    setLoader(loading)
    // eslint-disable-next-line
  }, [loading])

  if (elRefs.current.length !== songsLength) {
    elRefs.current = Array(songsLength).fill().map((_, i) => elRefs.current[i] || createRef())
  }

  useEffect(() => {
    if (activeFilters) {
      setMyFilters(TransformData(activeFilters))
      setOffset(0)
      setSongs([])
    }
    // eslint-disable-next-line
  }, [activeFilters])

  useEffect(() => {
    setOffset(0)
    setSongs([])
    setMySearch(search)
    // eslint-disable-next-line
  }, [search])

  useEffect(() => {
    if (Object.size(myFilters) || mySearch) {
      getSongs()
    }
    // eslint-disable-next-line
  }, [myFilters, mySearch])

  useEffect(() => {
    if (data && (Object.size(myFilters) || mySearch)) {
      if (offset === 0) {
        setSongs(data.AudiosFilterApp)
        setMaxoffset(data.AudiosFilterNumberApp)
      } else {
        setSongs(songs.concat(data.AudiosFilterApp))
      }
    }
    // eslint-disable-next-line
  }, [data])

  useEffect(() => {
    if (offset > 0) {
      getSongs()
    }
    // eslint-disable-next-line
  }, [offset])

  if (error) {
    console.log(error);
    return <div className="p-10 text-4xl font-bold text-gray-600">Error...</div>
  }

  if (!songs.length && !loading && (Object.size(myFilters) || mySearch)) {
    return  <div className="p-10 text-4xl font-bold text-gray-600">
      {activeFilters.map((filter, i) => {
        if (i === activeFilters.length - 1) {
          return <span key={i}><i>{filter.name}</i>{mySearch ? "" : "? "}</span>
        } else {
          return <span key={i}><i>{filter.name}</i> + </span>
        }
      })}
      {mySearch && <>{Object.size(myFilters) ? ' with' : ""} <i>{mySearch}</i>? </>}
      Are you sure?
    </div>
  }

  if (songs.length) {
    return (
      <div className="flex flex-col">
        {songs.map((song, i) => <Song key={song.ID} data={song} index={i} refs={elRefs} />)}
        {loading && <SongSkeleton />}
      </div>
    )
  }

  if (loading) {
    return <SongSkeleton />
  }
  return <div className="p-10 text-4xl font-bold text-gray-600"></div>
}