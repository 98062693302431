import React, { useContext, useState, useRef, useEffect } from 'react'
import { useQuery } from "@apollo/client"
import { LayoutContext } from '../contexts/Layout'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import FiltersQuery from "../graphql/queries/GetAllFilters";
import { theme } from './../theme/config'
import { authenticationService } from "../services/AuthenticationService"

const InputSearch = styled.button`
  svg {
    opacity: 0;
  }
  span {
    color: transparent;
    text-shadow: 0 0 0 white;
  }
  svg,
  span {
    transition: all 100ms ease-in-out;
  }
  &:hover {
    svg {
      opacity: 1;
    }
    span {
      padding-right: 1.2rem;
    }
  }
`

const filterTypes = [
  {
    name: 'Genre',
    key: 'Genero'
  },
  {
    name: 'Instrument',
    key: 'Instrumento'
  },
  {
    name: 'Language',
    key: 'Idioma'
  },
  {
    name: 'Tempo',
    key: 'Tempo'
  },
  {
    name: 'Theme',
    key: 'Animo'
  },
  {
    name: 'Voice',
    key: 'Voz'
  }
]

export default function Filters() {
  const { toggleActiveFilter, currentSong } = useContext(LayoutContext)
  const { loading, data, error } = useQuery(FiltersQuery)
  const [activeList, setActiveList] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const inputSearch = useRef(null)
  const [hideInputSearch, setHideInputSearch] = useState(true)
  const [windowHeight, setWindowHeight] = useState(null)
  const toggleActiveList = (index) => {
    if (activeList === index ) {
      setActiveList(null)
    } else {
      setActiveList(index)
      setSearchTerm('')
      setHideInputSearch(true)
    }
  }
  const getHiddeInputSearch = () => {
    setSearchTerm('')
    setHideInputSearch(true)
    setTimeout(() => {
      inputSearch.current.innerHTML = ''
      inputSearch.current.focus()
    }, 200)
  }
  const toggleFilter = (id, type, filter) => {
    toggleActiveFilter(id, type, filter)
    getHiddeInputSearch()
  }
  useEffect(() => {
    if (inputSearch.current) {
      inputSearch.current.focus()
      inputSearch.current.addEventListener('input', e => {
        setSearchTerm(e.target.innerHTML)
        if (e.target.innerHTML !== '') {
          setHideInputSearch(false)
        } else {
          setHideInputSearch(true)
        }
      })
    }
  },[activeList])
  useEffect(() => {
    setWindowHeight(window.innerHeight)
    window.addEventListener('resize', () => setWindowHeight(window.innerHeight))
  }, [])
  if (error) {
    authenticationService.logout()
  }
  return (
    <motion.div
      initial={{ x: '-100%'}}
      animate={{ x: 0 }}
      exit={{ x: '-100%', transition: { duration: 0.2 } }}
      transition={{ type: 'spring', damping: 20 }}
      className="fixed z-20 top-0 left-16 bottom-0 p-6 pr-8 bg-gray-900"
      style={{ width: theme.FILTER_SIDEBAR_WIDTH, paddingTop: theme.HEADER_HEIGHT }}
    >
      <h6 className="text-xs font-semibold mb-2 text-gray-500">SEARCH BY FILTERS</h6>
      <ul>
        {filterTypes.map((type, index) => (
          <li key={type.name} className={`${activeList === index && ``}`}>
            <button className="flex justify-between items-center w-full py-2" onClick={() => toggleActiveList(index)}>
              <h4 className={`font-semibold text-white ${activeList !== index && `text-gray-300`}`}>{type.name}</h4>
              <motion.span animate={{ rotate: activeList === index ? 180 : 0 }} className="-mr-1">
                <svg xmlns="http://www.w3.org/2000/svg" width={16} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </motion.span>
            </button>
            <motion.ul initial={false} animate={{ height: activeList === index ? 'auto' : '0' }} transition={{ type: 'spring', damping: 20 }} className="overflow-auto scroller" style={{ maxHeight:  `calc(${windowHeight}px - ${currentSong ? `500px`: `400px`})`}} >
              {activeList === index && (
                <li className={`absolute flex right-2 mt-1 bg-cyan-800 text-white rounded shadow-md items-center opacity-0 transition ${!hideInputSearch && `opacity-100`}`}>
                  <InputSearch className="flex items-center" onClick={() => getHiddeInputSearch() }>
                    <span ref={inputSearch} role="textbox" contentEditable className={`block lowercase text-xs text-right overflow-hidden font-light px-2 whitespace-nowrap focus:outline-none border-0 border-solid`} />
                    <svg className="absolute top-0.5 right-0.5" xmlns="http://www.w3.org/2000/svg" width={12} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </InputSearch>
                </li>
              )}
              {!loading && Object.keys(data.Filters).filter(index => index === type.key).map(typename => {
                let filters = data.Filters[typename].filter(name => name[typename].toLowerCase().includes(searchTerm.toLowerCase()))
                if (!filters.length) return activeList === index && <small className="opacity-50">{searchTerm}?</small>
                return (
                  filters.map(filter => (
                    filter[typename] !== "No Voice" && (
                      <li key={filter[`Id${typename}`]}>
                        <button className={`text-sm py-1 text-gray-300 hover:text-gray-400 w-full text-left`} onClick={(event) => {
                          toggleFilter(filter[`Id${typename}`], typename, filter[`${typename}`])
                          event.target.classList.toggle('active')
                        }}>
                          {filter[`${typename}`]}
                        </button>
                      </li>
                    )
                  ))
              )})}
            </motion.ul>
          </li>
        ))}
      </ul>
    </motion.div>
  )
}