import { gql } from '@apollo/client'

export default gql`
  query {
    User {
        Id
        Usuario
        dni
        creado
        nombre
        email
        limitar
        descmax
        desctotal
        documento
    }
  }
`
