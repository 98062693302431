import React from "react";
import { useParams } from "react-router-dom";

import { authenticationService } from "./AuthenticationService";

const RedirectRoute = () => {
    const { token } = useParams();

    const closePage = () => {
        window.close();
    }

    const currentUser = authenticationService.currentUserValue;
      if (!currentUser) {
        localStorage.setItem('currentUser', token);
        setTimeout(() => {
            closePage()
        }, 500);
    } else {
        closePage()
    }

    return (
        <span>Seras redireccionado pronto, en caso que no, haz click <span onClick={closePage} style={{ cursor: "pointer", color: "blue" }}>Aqui</span></span>
    )
}

export default RedirectRoute;
