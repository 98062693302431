import React, { useContext, useState } from "react";
import { LayoutContext } from "../contexts/Layout";
import { motion } from "framer-motion";
import { theme } from "./../theme/config";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function InfoPanel() {
  const { currentSong, setInfoPanel } = useContext(LayoutContext);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const {
    AudioNombre: nombre,
    AudioInterprete: interprete,
    AudioCompositor: compositor,
    AudioProductor_: productor,
    AudioISRC: isrc,
    AudioISWC: iswc,
    AudioGeneros_: genres,
    AudioAnimo_: themes,
    AudioInstrumento_: instruments,
    AudioIdioma_: languages,
    AudioVoz_: voices,
    AudioPublisher_: publisher,
    AudioTempo_: tempos,
  } = currentSong;

  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      exit={{ x: "100%" }}
      transition={{ type: "spring", damping: 18 }}
      className="fixed w-full z-40 top-0 right-0 text-white bg-gray-900 bg-opacity-90 px-5 py-4 overflow-auto scroller"
      style={{
        maxWidth: theme.INFO_PANEL_WIDTH,
        bottom: 112,
        backdropFilter: "blur(5px)",
      }}
    >
      <button
        className="fixed top-4.5 right-4 hover:text-cyan-400"
        onClick={() => setInfoPanel(false)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={18}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <div className="flex items-center space-x-2 text-gray-600 mb-8">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={18}
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clipRule="evenodd"
          />
        </svg>
        <h6 className="text-xs font-semibold">SONG INFO</h6>
        {"\u00A0"}
        <CopyToClipboard
          text={`
          *TITLE*
          ${nombre}
          *ARTIST*
          ${interprete}
          *COMPOSER*
          ${compositor}
          *PRODUCER*
          ${productor}
          *PUBLISHER*
          ${publisher ? publisher : "---"}
          *ISRC*
          ${isrc ? isrc : `N/A`}
          *ISWC*
          ${iswc ? iswc : `N/A`}
        `}
        >
          <div className="hover:text-white cursor-pointer flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              width={18}
              className="cursor-pointer "
            >
              <path d="M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0016.414 5L14 2.586A2 2 0 0012.586 2H9z" />
              <path d="M3 8a2 2 0 012-2v10h8a2 2 0 01-2 2H5a2 2 0 01-2-2V8z" />
            </svg>
            <span className="text-xs">Copy All</span>
          </div>
        </CopyToClipboard>
      </div>
      <h6 className="text-xs font-semibold text-gray-600 uppercase">Title</h6>
      <div className="flex">
        <h4 className="font-semibold mb-5 uppercase">{nombre}</h4>
        {"\u00A0"}
        <CopyToClipboard text={nombre}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            width={18}
            className="cursor-pointer mb-5"
          >
            <path d="M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0016.414 5L14 2.586A2 2 0 0012.586 2H9z" />
            <path d="M3 8a2 2 0 012-2v10h8a2 2 0 01-2 2H5a2 2 0 01-2-2V8z" />
          </svg>
        </CopyToClipboard>
      </div>
      <h6 className="text-xs font-semibold text-gray-600 uppercase">Artist</h6>
      <div className="flex">
        <h4 className="font-semibold mb-5 uppercase">{interprete}</h4>
        {"\u00A0"}
        <CopyToClipboard text={interprete}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            width={18}
            className="cursor-pointer mb-5"
          >
            <path d="M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0016.414 5L14 2.586A2 2 0 0012.586 2H9z" />
            <path d="M3 8a2 2 0 012-2v10h8a2 2 0 01-2 2H5a2 2 0 01-2-2V8z" />
          </svg>
        </CopyToClipboard>
      </div>
      <h6 className="text-xs font-semibold text-gray-600 uppercase">
        Composer
      </h6>
      <div className="flex">
        <h4 className="mb-5 font-semibold uppercase">{compositor}</h4>
        {"\u00A0"}
        <CopyToClipboard text={compositor}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            width={18}
            className="cursor-pointer mb-5"
          >
            <path d="M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0016.414 5L14 2.586A2 2 0 0012.586 2H9z" />
            <path d="M3 8a2 2 0 012-2v10h8a2 2 0 01-2 2H5a2 2 0 01-2-2V8z" />
          </svg>
        </CopyToClipboard>
      </div>
      <h6 className="text-xs font-semibold text-gray-600 uppercase">
        Producer
      </h6>
      <div className="flex">
        <h4 className="mb-5 font-semibold uppercase">{productor}</h4>
        {"\u00A0"}
        <CopyToClipboard text={productor}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            width={18}
            className="cursor-pointer mb-5"
          >
            <path d="M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0016.414 5L14 2.586A2 2 0 0012.586 2H9z" />
            <path d="M3 8a2 2 0 012-2v10h8a2 2 0 01-2 2H5a2 2 0 01-2-2V8z" />
          </svg>
        </CopyToClipboard>
      </div>
      <h6 className="text-xs font-semibold text-gray-600 uppercase">
        Publisher
      </h6>
      <div className="flex">
        <h4 className="mb-5 font-semibold uppercase">
          {publisher ? publisher : "---"}
        </h4>
        {"\u00A0"}
        <CopyToClipboard text={publisher ? publisher : "---"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            width={18}
            className="cursor-pointer mb-5"
          >
            <path d="M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0016.414 5L14 2.586A2 2 0 0012.586 2H9z" />
            <path d="M3 8a2 2 0 012-2v10h8a2 2 0 01-2 2H5a2 2 0 01-2-2V8z" />
          </svg>
        </CopyToClipboard>
      </div>
      <h6 className="text-xs font-semibold text-gray-600 uppercase">ISRC</h6>
      <div className="flex">
        <h4 className="mb-5 font-semibold">{isrc ? isrc : `N/A`}</h4>
        {"\u00A0"}
        <CopyToClipboard text={isrc ? isrc : `N/A`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            width={18}
            className="cursor-pointer mb-5"
          >
            <path d="M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0016.414 5L14 2.586A2 2 0 0012.586 2H9z" />
            <path d="M3 8a2 2 0 012-2v10h8a2 2 0 01-2 2H5a2 2 0 01-2-2V8z" />
          </svg>
        </CopyToClipboard>
      </div>
      <h6 className="text-xs font-semibold text-gray-600 uppercase">ISWC</h6>
      <div className="flex">
        <h4 className="mb-8 font-semibold">{iswc ? iswc : `N/A`}</h4>
        {"\u00A0"}
        <CopyToClipboard text={iswc ? iswc : `N/A`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            width={18}
            className="cursor-pointer mb-8"
          >
            <path d="M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0016.414 5L14 2.586A2 2 0 0012.586 2H9z" />
            <path d="M3 8a2 2 0 012-2v10h8a2 2 0 01-2 2H5a2 2 0 01-2-2V8z" />
          </svg>
        </CopyToClipboard>
      </div>
      <div className="flex items-center space-x-2 mb-6 text-gray-600">
        <button onClick={() => setFiltersVisible(!filtersVisible)}>
          {filtersVisible ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
              <path
                fillRule="evenodd"
                d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
                clipRule="evenodd"
              />
              <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
            </svg>
          )}
        </button>
        <button
          onClick={() => setFiltersVisible(!filtersVisible)}
          className="text-xs font-semibold text-gray-600 uppercase"
        >
          Filters
        </button>
      </div>
      {filtersVisible && (
        <>
          <h6 className="text-xs font-semibold text-gray-600 uppercase mb-2">
            Genres
          </h6>
          {genres.split(",").map((genre) => (
            <h5 className="inline-block text-xs border border-gray-800 p-3 rounded-lg mr-2 mb-2">
              {genre}
            </h5>
          ))}
          <h6 className="text-xs font-semibold text-gray-600 uppercase mb-2 mt-4">
            Themes
          </h6>
          {themes.split(",").map((genre) => (
            <h5 className="inline-block text-xs border border-gray-800 p-3 rounded-lg mr-2 mb-2">
              {genre}
            </h5>
          ))}
          <h6 className="text-xs font-semibold text-gray-600 uppercase mb-2 mt-4">
            Instruments
          </h6>
          {instruments.split(",").map((genre) => (
            <h5 className="inline-block text-xs border border-gray-800 p-3 rounded-lg mr-2 mb-2">
              {genre}
            </h5>
          ))}
          <h6 className="text-xs font-semibold text-gray-600 uppercase mb-2 mt-4">
            Languages
          </h6>
          {languages.split(",").map((genre) => (
            <h5 className="inline-block text-xs border border-gray-800 p-3 rounded-lg mr-2 mb-2">
              {genre}
            </h5>
          ))}
          <h6 className="text-xs font-semibold text-gray-600 uppercase mb-2 mt-4">
            Voices
          </h6>
          {voices.split(",").map((genre) => (
            genre.trim() !== "No Voice" && ( // Assegureu-vos de fer una comparació després de treure els espais en blanc amb trim()
              <h5 className="inline-block text-xs border border-gray-800 p-3 rounded-lg mr-2 mb-2">
                {genre}
              </h5>
            )
          ))}

          <h6 className="text-xs font-semibold text-gray-600 uppercase mb-2 mt-4">
            Tempo
          </h6>
          {tempos.split(",").map((genre) => (
            <h5 className="inline-block text-xs border border-gray-800 p-3 rounded-lg mr-2 mb-2">
              {genre}
            </h5>
          ))}
        </>
      )}
    </motion.div>
  );
}
