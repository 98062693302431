import React, { useState, createRef, useRef } from "react";
import Logo from "./LogoPlaylist";
import { useParams } from "react-router-dom";
import PlayListSong from "../components/PlayListSong";
import AudioPlayer from "./AudioPlayerPlayList";
import InfoPanel from "./InfoPanelPlayList";
import axios from "axios";

function PlayList() {
  const { slug } = useParams();
  const [project, setProject] = useState(null);
  const [audios, setAudios] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentSong, setCurrentSong] = useState(null);
  const [infoPanel, setInfoPanel] = useState(false);
  const elRefs = useRef([]);
  const [isDownloading, setIsDownloading] = useState(false);

  const requestOptions = {
    method: "post",
    url: "https://musichall-api.jbcmusic.es/api/getPlayListSongs",
    // url: "http://localhost:5001/api/getPlayListSongs",
    data: {
      slug: slug,
    },
  };

  const init = async () => {
    try {
      const response = await axios(requestOptions);
      setProject(response.data.project);
      setAudios(response.data.audios);
      elRefs.current = Array(response.data.audios.length)
        .fill()
        .map((_, i) => {
          let me = createRef();
          return elRefs.current[i] || me;
        });
      setLoading(false);
    } catch (error) {
      setError(true);
    }
  };

  const downloadPlayList = () => {
    setIsDownloading(true);
    const requestOptions = {
      method: "post",
      url: "https://musichall-api.jbcmusic.es/api/getSongsPL",
      // url: "http://localhost:5001/api/getSongsPL",
      responseType: "arraybuffer",
      data: {
        id: project.id,
      },
    };

    axios(requestOptions).then((res) => {
      let blob = new Blob([res.data], { type: "application/zip" });
      const downloadUrl = URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = downloadUrl;
      a.download = project.slug + ".zip";
      document.body.appendChild(a);
      a.click();
      setIsDownloading(false);
    });
  };

  React.useEffect(() => {
    init();
  }, []);

  if (error) {
    return (
      <div className="grid grid-cols-12 gap-4">
        <div className="container-playlist-background col-start-3 col-span-8 mt-14">
          <div className="p-8">
            <div className="w-32">
              <Logo />
            </div>
            <hr className="hr-color mt-2" />
          </div>
          <div className="pr-8 pl-8 pb-8">
            Internal system error. Please contact administrator if the problem
            persists.
          </div>
        </div>
      </div>
    );
  }

  if (loading || !project) {
    return (
      <div className="grid grid-cols-12 gap-4">
        <div className="container-playlist-background col-start-3 col-span-8 mt-14">
          <div className="p-8">
            <div className="w-32">
              <Logo />
              {/* #c1c1c1 */}
            </div>
            <hr className="hr-color mt-2" />
          </div>
          <div className="animate-pulse flex space-x-4">
            <div className="pr-8 pl-8 pb-8 flex-1 space-y-4 py-1">
              <div className="h-4 bg-current rounded w-3/4"></div>
              <div className="space-y-2">
                <div className="h-4 bg-current rounded w-5/6 mt-4"></div>
                <div className="h-4 bg-current rounded w-5/6 "></div>
                <div className="h-4 bg-current rounded w-5/6 "></div>
                <div className="h-4 bg-current rounded w-5/6 "></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="container-playlist-background col-start-3 col-span-8 mt-14 mb-28">
        <div className="p-8">
          <div className="flex justify-between items-end">
            <div className="w-32">
              <Logo />
            </div>
            <button
              onClick={downloadPlayList}
              className={`mitad flex space-x-3 items-center rounded py-3 font-bold hover:bg-cyan-300 text-gray-900 transitions duration-200 whitespace-nowrap px-5 bg-cyan-400 ${
                isDownloading ? "opacity-25 pointer-events-none" : ""
              }`}
            >
              {isDownloading ? (
                <svg
                  className=""
                  width={18}
                  viewBox="0 0 38 38"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#fff"
                >
                  <g fill="none" fillRule="evenodd">
                    <g transform="translate(1 1)" strokeWidth="2">
                      <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                      <path stroke="white" d="M36 18c0-9.94-8.06-18-18-18">
                        <animateTransform
                          attributeName="transform"
                          type="rotate"
                          from="0 18 18"
                          to="360 18 18"
                          dur="1s"
                          repeatCount="indefinite"
                        />
                      </path>
                    </g>
                  </g>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={18}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="hover:opacity-80"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                  />
                </svg>
              )}
              {"\u00A0"}
              {isDownloading ? "Downloading Playlist" : "Download Playlist"}
            </button>
          </div>
          <hr className="hr-color mt-2" />
        </div>
        <div className="pr-8 pl-8 pb-8">
          <h1 className="text-4xl font-semibold mb-3">{project.name}</h1>
          {audios &&
            audios.map((audio, i) => {
              return (
                <PlayListSong
                  key={audio.ID}
                  data={audio}
                  index={i}
                  refs={elRefs}
                  filename={audio.slugUrl}
                  currentSong={currentSong}
                  setCurrentSong={setCurrentSong}
                />
              );
            })}
        </div>
      </div>
      {currentSong && (
        <AudioPlayer
          key={1}
          currentSong={currentSong}
          infoPanel={infoPanel}
          setInfoPanel={setInfoPanel}
        />
      )}
      {infoPanel && (
        <InfoPanel
          key={2}
          currentSong={currentSong}
          setInfoPanel={setInfoPanel}
        />
      )}
    </div>
  );
}

export default PlayList;
