import styled from '@emotion/styled'

const EffectHalftone = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  filter: contrast(2000%);
  mix-blend-mode: darken;
  opacity: .05;
  z-index: -1;
  video, iframe, img {
    filter: brightness(0.8) blur(4px) saturate(0) contrast(.6);
  }
  &::after {
    content: '';
    position: absolute;
    top: -100%;
    left: -100%;
    right: -100%;
    bottom: -100%;
    background-blend-mode: multiply;
    background: radial-gradient(8px 8px, black, white);
    background-size: 10px 10px;
    background-position: 0 0;
    mix-blend-mode: soft-light;
    pointer-events: none;
    transform: rotate(45deg);
    z-index: 0;
  }
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 250%;
    height: 280%;
    background-image: radial-gradient(#000000f21 20%,white 50%);
    opacity: 1;
    background-position: center;
    background-size: 90% 131%;
    background-attachment: inherit;
    z-index: 999999;
  }
`

export default EffectHalftone