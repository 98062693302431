import React, { useContext, useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import { motion } from "framer-motion";
import { ProjectsContext } from "../contexts/ProjectsContext";
import { LayoutContext } from "../contexts/Layout";
import { printDuration, normalizedPeaks } from "../services/SongsServices";
import { Play, Pause, Info } from "react-feather";
import VolumeSlider from "./VolumeSlider";
import ReactTooltip from "react-tooltip";

export default function AudioPlayer() {
  const { projects, addToProject, addProjectButton, addToProjectIsLoading } =
    useContext(ProjectsContext);
  const {
    currentSong,
    projectSelectorIsOpen,
    setProjectSelectorIsOpen,
    infoPanel,
    setInfoPanel,
  } = useContext(LayoutContext);
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [playing, setPlay] = useState(null);
  const [currentTime, setCurrentTime] = useState("0:00");
  const [remainTime, setRemainTime] = useState("0:00");
  const [audioIsLoading, setAudioIsLoading] = useState(true);

  const wrapStyled = {
    backgroundImage: "linear-gradient(180deg, #2f3234, #0f1010d4)",
    backdropFilter: "blur(5px)",
    paddingRight: addProjectButton ? 140 : null,
  };

  useEffect(() => {
    const options = {
      container: waveformRef.current,
      waveColor: "#484848",
      progressColor: "white",
      cursorColor: "transparent",
      barWidth: 1,
      barRadius: 8,
      responsive: true,
      height: 70,
      normalize: true,
      pixelRatio: 10,
      barGap: 3,
      hideScrollbar: true,
      backend: "MediaElement",
    };
    wavesurfer.current = WaveSurfer.create(options);
    if (currentSong) {
      wavesurfer.current.load(
        `https://musichall-api.jbcmusic.es/audio/${currentSong.slugUrl}`,
        normalizedPeaks(JSON.parse(currentSong.Waveform))
      );
    }

    setRemainTime(`-${printDuration(currentSong.AudioDuracion)}`);
    // wavesurfer.current.on("loading", (percents) => {
    // setAudioIsLoading(true);
    // console.log(percents)
    // });
    wavesurfer.current.on("ready", function () {
      console.log("ready");
      setAudioIsLoading(false);
      wavesurfer.current.play();
      wavesurfer.current.setVolume(0.5);
      wavesurfer.current.on("play", () => setPlay(true));
      wavesurfer.current.on("pause", () => setPlay(false));
      wavesurfer.current.on(
        "seek",
        () => wavesurfer.current.isPlaying() && wavesurfer.current.play()
      );
      wavesurfer.current.on("finish", () => setPlay(false));
      wavesurfer.current.on("audioprocess", (position) => {
        setCurrentTime(printDuration(Math.floor(position)));
        setRemainTime(
          `-${printDuration(wavesurfer.current.getDuration() - position)}`
        );
      });
    });
    return () => {
      wavesurfer.current.destroy();
      setAudioIsLoading(true);
    };
  }, [currentSong]);

  const handlePlayPause = () => {
    wavesurfer.current.playPause();
    setPlay(!playing);
  };

  const onVolumeChange = (event) =>
    wavesurfer.current.setVolume(event.target.value);

  const toggleMute = () => wavesurfer.current.toggleMute();

  const handleProject = () => {
    const activeProjects = projects.filter(
      (project) => project && !project.state
    );
    const activeProjectID = activeProjects[0] ? activeProjects[0].id : null;
    if (activeProjects.length > 1) {
      setProjectSelectorIsOpen(!projectSelectorIsOpen);
    } else {
      addToProject(activeProjectID, currentSong);
    }
  };

  return (
    <motion.div
      initial={{ y: "100%" }}
      animate={{ y: 0 }}
      exit={{ y: "100%" }}
      style={wrapStyled}
      transition={{ type: "spring", damping: 20 }}
      className="fixed z-55 flex items-center space-x-8 h-28 bottom-0 left-0 right-0 pr-5 pl-8 border-t-4 border-gray-800"
    >
      {!playing && !audioIsLoading ? (
        <button className="hover:opacity-80" onClick={handlePlayPause}>
          <Play size={35} strokeWidth={1} stroke="white" />
        </button>
      ) : (
        <button
          className="hover:opacity-80"
          onClick={handlePlayPause}
          disabled={audioIsLoading}
        >
          <Pause size={35} strokeWidth={1} stroke="cyan" />
        </button>
      )}
      <button
        className="hover:opacity-80"
        onClick={() => setInfoPanel(!infoPanel)}
      >
        <Info
          size={24}
          strokeWidth={infoPanel ? 2 : 1}
          stroke={infoPanel ? `cyan` : `white`}
        />
      </button>
      <time>{currentTime}</time>
      <div
        className={`w-full ${audioIsLoading && `pointer-events-none `}`}
        ref={waveformRef}
      />

      <time>{remainTime}</time>
      <VolumeSlider onVolumeChange={onVolumeChange} toggleMute={toggleMute} />
      {!addProjectButton && (
        <motion.button
          whileTap={{ scale: !addToProjectIsLoading ? 0.8 : 1 }}
          data-tip="Add to project"
          effe
          className="transition bg-gray-900 hover:bg-cyan-400 hover:text-gray-900 rounded-full p-5 border-4 border-gray-700 border-solid"
          onClick={handleProject}
        >
          {!addToProjectIsLoading ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={35}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              />
            </svg>
          ) : (
            <svg
              className=""
              width="35"
              height="35"
              viewBox="0 0 38 38"
              xmlns="http://www.w3.org/2000/svg"
              stroke="#fff"
            >
              <g fill="none" fillRule="evenodd">
                <g transform="translate(1 1)" strokeWidth="2">
                  <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                  <path stroke="black" d="M36 18c0-9.94-8.06-18-18-18">
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      from="0 18 18"
                      to="360 18 18"
                      dur="1s"
                      repeatCount="indefinite"
                    />
                  </path>
                </g>
              </g>
            </svg>
          )}
          <ReactTooltip />
        </motion.button>
      )}
    </motion.div>
  );
}
