import React, { useState } from "react";
import SongList from "./SongList";
import { useBottomScrollListener } from "react-bottom-scroll-listener";

export default function SongListPageTest() {
  const [offset, setOffset] = useState(0);
  const [maxOffset, setMaxoffset] = useState(null);
  const handleScroll = () => {
    if (offset < maxOffset) {
      setOffset(offset + 60);
    }
  };
  useBottomScrollListener(handleScroll, 300);
  return (
    <SongList
      offset={offset}
      setOffset={setOffset}
      maxOffset={maxOffset}
      setMaxoffset={setMaxoffset}
    />
  );
}
