import { gql } from '@apollo/client'

export default gql`
  query {
    GetTermsFile {
      id
      position
      editable
      key_
      name
      type
      value
    }
  }
`
