import React, { createContext, useState, useEffect, useContext } from 'react'
import { ProjectsContext } from '../contexts/ProjectsContext'
import { NavLink } from "react-router-dom";
import Header from '../components/Header'
import Logo from '../components/Logo'
import Navigation from '../components/Navigation'
import InputSearch from '../components/InputSearch'
import NewProject from '../components/NewProject'
import Filters from '../components/Filters'
import AudioPlayer from '../components/AudioPlayer'
import ProjectSelector from '../components/ProjectSelector'
import Spinner from '../components/Spinner'
import InfoPanel from '../components/InfoPanel'
import { theme } from './../theme/config'
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import { AnimatePresence, motion } from 'framer-motion'
import GetTermsState from "../graphql/queries/getTermsState"
import AcceptTerms from "../graphql/mutations/AcceptTerms"
import { useQuery, useMutation } from '@apollo/client';
import { authenticationService } from "../services/AuthenticationService"

export const LayoutContext = createContext();

export default function Layout({ children, location }) {
  const { addProjectButton } = useContext(ProjectsContext)
  const [inputSearchIsFocus, setInputSearchIsFocus] = useState(false)
  const [pathname, setPathname] = useState(null)
  const [filtersSidebarIsOpen, setFiltersSidebarIsOpen] = useState(true)
  const [projectSelectorIsOpen, setProjectSelectorIsOpen ] = useState(false)
  const [activeFilters, setActiveFilters] = useState([])
  const [search, setSearch] = useState("")
  const isFiltersSidebarOpen = filtersSidebarIsOpen && pathname === "/" && inputSearchIsFocus ? true : false
  const [currentSong, setCurrentSong] = useState(null)
  const [loader, setLoader] = useState(false)
  const [infoPanel, setInfoPanel] = useState(false)
  const { loading, error, data } = useQuery(GetTermsState)
  const [termsAlert, setTermsAlert] = useState(data ? !data.GetTermsState : false)
  const [ acceptTerms ] = useMutation(AcceptTerms)

  const handleAcceptTerms = () => {
    acceptTerms().then(res => {
      setTermsAlert(false)
    })
  }

  const toggleActiveFilter = (id, type, filter) => {
    if (!activeFilters.filter(active => id === active.id && type === active.type).length) {
      setActiveFilters([
        ...activeFilters,
        {
          id: id,
          name: filter,
          type: type
        }
      ])
    } else {
      setActiveFilters(activeFilters.filter(active => !(id === active.id && type === active.type)))
    }
  }

  useEffect(() => {
    if (!loading && data) {
        setTermsAlert(!data.GetTermsState)
    }
  }, [loading, data, error])

  useEffect(() => {
    setPathname(location.pathname)
  }, [location])
  
  const isHome = !inputSearchIsFocus && pathname === '/'
  if (error) {
    authenticationService.logout()
  }
  return (
    <LayoutContext.Provider
      value={{
        inputSearchIsFocus,
        setInputSearchIsFocus,
        pathname,
        filtersSidebarIsOpen,
        setFiltersSidebarIsOpen,
        activeFilters,
        setActiveFilters,
        toggleActiveFilter,
        currentSong,
        setCurrentSong,
        search,
        setSearch,
        loader,
        setLoader,
        ToastsStore,
        projectSelectorIsOpen,
        setProjectSelectorIsOpen,
        infoPanel,
        setInfoPanel
      }}
    >  
      <Spinner loading={loader}/>
      {authenticationService.currentUserValue.admin && 
        <div className="fixed z-50 top-0 left-8 w-full text-xs font-bold px-2 flex justify-center space-x-5 py-0.5 font-mono">
          <div className="flex space-x-2 items-center">
            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="12" fill="currentColor">
              <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
            </svg> */}
            <svg xmlns="http://www.w3.org/2000/svg" width="12" viewBox="0 0 20 20" fill="currentColor">
              <path d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z" />
            </svg>
            <span> admin</span>
          </div>
          <div className="space-x-5">
            <a href="https://admin.jbcmusic.es/dashboard" target="_blank" rel="noreferrer" className="text-gray-500 hover:text-white">/dashboard</a>
            <a href="https://admin.jbcmusic.es/songs" target="_blank" rel="noreferrer" className="text-gray-500 hover:text-white">/songs</a>
            <a href="https://admin.jbcmusic.es/projects" target="_blank" rel="noreferrer" className="text-gray-500 hover:text-white">/projects</a>
            <a href="https://admin.jbcmusic.es/users" target="_blank" rel="noreferrer" className="text-gray-500 hover:text-white">/users</a>
          </div>
        </div>
      }
      <motion.div
        className="fixed w-64 top-4 left-2 z-40 p-5 origin-top-left"
        initial={{ x: "calc(50vw - 50% + 1.5rem)", y: 50 }}
        animate={{ x: "calc(50vw - 50% + 1.5rem)", y: isHome ? 50 : -400 }}
        transition={{ type: 'spring', damping: 18 }}
      >
        <NavLink to="/">
          <Logo />
        </NavLink>
      </motion.div>
      <Navigation />
      <Header>
        <AnimatePresence>
          {pathname && 
            <motion.h1 
              key={pathname} 
              initial={{ y: -0, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 0, opacity: 0 }}
              className={`fixed top-7 left-16 pl-10 font-bold capitalize ${!/project\//.test(pathname) ? `text-4xl top-6 left-10` : `left-10`}`}
            >
              {/project\//.test(pathname) ? <NavLink to="/projects">{pathname.split('/')[1]}</NavLink> : pathname.split('/')[1]}
            </motion.h1>
          }
        </AnimatePresence>
        {/* <motion.h2
          initial={{ y: -400 }}
          animate={{ y: isHome ? 150 : -400 }}
          transition={{ type: 'spring', damping: 20 }}
          className={`fixed top-0 z-10 text-2xl text-center uppercase font-bold`}
        >
          <h1 className="font-bold text-5xl">JBC <span className="text-cyan-400">MUSIC HALL</span></h1>
        </motion.h2> */}
        <motion.h2
          initial={{ y: -400 }}
          animate={{ y: isHome ? 260 : (inputSearchIsFocus && /^\/$/.test(pathname) && !search && !activeFilters.length) ? 200 : -400 }}
          transition={{ type: 'spring', damping: 20 }}
          
          className="fixed top-0 z-10 text-2xl font-bold -ml-16"
        >
          The <b className="text-cyan-400">fastest tool</b> to find high quality music
        </motion.h2>
        
      </Header>
      <InputSearch />
      <main
        className="ml-16"
        style={{ 
          paddingTop:  /^\/$/.test(pathname) && !inputSearchIsFocus ? `60vh` : theme.HEADER_HEIGHT,
          paddingLeft: isFiltersSidebarOpen ? theme.FILTER_SIDEBAR_WIDTH : 0,
          marginBottom: 150,
          transition: 'all 500ms cubic-bezier(0.2, 0, 0, 1)' 
        }}
      >
        { children }
        <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_CENTER} />
      </main>
      <AnimatePresence>
        {currentSong && <AudioPlayer key={1} />}
        {infoPanel && !isHome && <InfoPanel key={2} />}
        {filtersSidebarIsOpen && !isHome && /^\/$/.test(pathname) && <Filters key={3} />}
        {projectSelectorIsOpen && <ProjectSelector key={4} />}
        {(addProjectButton || /projects/.test(pathname)) && <NewProject key={5} />}
        {termsAlert && (
          <motion.div initial={{ y: 150 }} animate={{ y: 0, opacity: 1 }} exit={{ y: 0, opacity: 0 }} key={6} className={`fixed left-40 right-40 bottom-5 flex justify-between items-center space-x-5 rounded-full bg-blue-800 bg-opacity-90 px-8 py-3 shadow-2xl transition-all duration-300 ${currentSong && `bottom-32`}`} style={{ zIndex: 29 }}>
            <div className="flex items-center space-x-3">
              <svg xmlns="http://www.w3.org/2000/svg" width={24} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
              <span>You must accept terms and conditions</span>
            </div>
            <button className="bg-white rounded px-5 py-2 hover:bg-opacity-80 text-blue-800 font-semibold shadow-md" onClick={handleAcceptTerms}>Aceptar</button>
          </motion.div>
        ) }
      </AnimatePresence>
    </LayoutContext.Provider>
  )
}