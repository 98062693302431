import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '../helpers/HandleResponse';
import axios from "axios"

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

function login (usuario, password, ToastsStore) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ usuario, password })
  };

  return fetch(`https://musichall-api.jbcmusic.es/api/signin`, requestOptions)
    .then(handleResponse)
    .catch(err => {
      if (err === "Email o contraseña incorrectos") {
        ToastsStore.error("The user name or password is incorrect", 4000)
      } else {
        ToastsStore.error("Internal system error. Please contact administrator if the problem persists.", 10000)
      }
    })
    .then(user => {
      if (user) {
        localStorage.setItem('currentUser', JSON.stringify(user.user));
        currentUserSubject.next(user.user);
        return user;
      }
    })
}

function logout() {
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}

function changePassword(password) {
  const requestOptions = {
    method: "post",
    url: "https://musichall-api.jbcmusic.es/api/resetpassword",
    headers: {
      authorization: `Bearer ${ currentUserSubject.value.token }`,
    },
    data: {
      idUser: currentUserSubject.value.id,
      password: password
    }
  };
  axios(requestOptions).then(res => {
    if (res.data && res.data.state) {
      logout()
      window.location.reload();
    }
  })
}

export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    changePassword,
    get currentUserValue () { return currentUserSubject.value }
};
