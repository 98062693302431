import React, { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { ProjectsContext } from "../contexts/ProjectsContext";
import { LayoutContext } from "../contexts/Layout";
import { filterProjects } from "../services/SongsServices";
import { theme } from "./../theme/config";

export default function ProjectSelector() {
  const { projects: ctxProjects, addToProject } = useContext(ProjectsContext);
  const { currentSong, setProjectSelectorIsOpen } = useContext(LayoutContext);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const res = filterProjects(ctxProjects);
    setProjects(res[0]);
  }, [ctxProjects]);

  const handleClick = (projectID) => {
    addToProject(projectID, currentSong);
    setTimeout(() => {
      setProjectSelectorIsOpen(false);
    }, 1000);
  };
  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      exit={{ x: "100%" }}
      transition={{ type: "spring", damping: 18 }}
      className="fixed z-50 top-0 right-0 text-white bg-gray-900 bg-opacity-90 py-4 overflow-auto overflow-color-dark"
      style={{
        width: theme.INFO_PANEL_WIDTH,
        bottom: 112,
        backdropFilter: "blur(5px)",
      }}
    >
      <button
        className="absolute top-4.5 right-4 hover:text-cyan-400"
        onClick={() => setProjectSelectorIsOpen(false)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={18}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      {currentSong && (
        <>
          <div className="flex items-center space-x-2 px-5 text-gray-600 mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={18}
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                clipRule="evenodd"
              />
            </svg>
            <h6 className="text-xs font-semibold">ADD TO PROJECT</h6>
          </div>
          {projects.map((project) => (
            <button
              className="flex space-x-3 items-center px-5 py-5 font-bold text-white hover:bg-gray-800 w-full"
              onClick={() => handleClick(project.id)}
            >
              {project.name}
            </button>
          ))}
        </>
      )}
    </motion.div>
  );
}
