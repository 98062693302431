import React, { useEffect, useState, useContext } from 'react'
import { useMutation } from "@apollo/client"
import FinishProject from "../graphql/mutations/FinishProject"
import { ProjectsContext } from '../contexts/ProjectsContext'
import { motion } from 'framer-motion'
import { ToastsStore } from 'react-toasts'

export default function ProjectFinishModal({ close, project, setProject }){
  const { projects, setProjects, setAddProjectButton } = useContext(ProjectsContext);
  const [confirmedSongs, setConfirmedSongs] = useState([]);
  const [finishProject, { data: dataMutation, loading: loadingMutation }] = useMutation(FinishProject)

  useEffect(() => {
    if (dataMutation && !loadingMutation) {
      setProjects(projects.filter(proj => proj.id !== project.id).concat(dataMutation.finishProject))
      setProject(dataMutation.finishProject)
      close()
    }
  }, [loadingMutation, dataMutation, close, project, projects, setProject, setProjects])

  useEffect(() => {
    projects && setAddProjectButton(!projects.filter(project => !project.state).length)
  }, [projects, setAddProjectButton])

  const finishProject_ = () => {
    if (confirmedSongs.length) {
      finishProject({variables: {
        id: project.id,
        songs: JSON.stringify(confirmedSongs)
      }})
    } else {
      ToastsStore.warning("Select at least one song")
    }
  }

  const onHandleChange = (e) => {
    if (confirmedSongs.indexOf(e.target.value) > -1) {
      setConfirmedSongs(confirmedSongs.filter(id => id !== e.target.value))
    } else {
      setConfirmedSongs([e.target.value].concat(confirmedSongs))
    }
  }

  return (
    <div className="fixed w-screen flex top-0 left-0 right-0 bottom-0 z-50 overflow-auto pt-20 pb-40 scroller">
      <motion.div
        initial={{ scale: 1, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 1, opacity: 0 }}
        className="relative z-10 m-auto px-10 py-6 w-3/6 bg-gray-800 rounded-2xl"
        transition={{ type: 'spring', damping: 20 }}
      >
        <button className="absolute top-4 right-4 hover:text-cyan-400" onClick={close}>
          <svg xmlns="http://www.w3.org/2000/svg" width={32} fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </button>
        <small className="text-lg font-semibold">Finalize project</small>
        <h2 className="text-5xl font-bold mb-5">{project.name}</h2>
        {/* <p>Curabitur vitae interdum nibh. Integer mollis varius neque, sed porttitor arcu consectetur ultricies. Cras at finibus quam. Curabitur placerat dictum dignissim. Proin tempor venenatis eros, eu faucibus dui condimentum vitae.</p> */}
        <div className="-mx-10 my-10 space-y-5">
          {project.audios.map((audio, index) =>
            <div className="flex items-center px-5 space-x-5">
              <input
                id={`input${index}`} 
                type="checkbox" 
                value={audio.ID} 
                onChange={onHandleChange}
              />
              <label for={`input${index}`}>
                <h3 className="font-bold">{audio.AudioNombre}</h3>
                <h5 className="">{audio.AudioInterprete}</h5>
              </label>
            </div>
          )}
        </div>
        {(project.audiosNumber >= 1) ? 
          <button className="space-x-3 text-xl rounded px-5 py-5 w-full text-center font-bold bg-cyan-400 hover:bg-cyan-300 text-gray-900" onClick={finishProject_}>Finalize project</button>
          :
          <button className="space-x-3 text-xl rounded px-5 py-5 w-full text-center font-bold bg-gray-600 hover:bg-cyan-300 text-gray-900">Finalize project</button>
        }
      </motion.div>
      <motion.div
        onClick={close}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { duration: 0.2 } }}
        className="fixed w-screen h-screen top-0 left-0 bg-gray-900 bg-opacity-90"
        style={{
          backdropFilter: 'blur(10px)'
        }}
      />
    </div>
  )
}