import React, { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

export default function VolumeSlider({ onVolumeChange, toggleMute }) {
  const [rangeIsVisible, setRangeIsVisible] = useState(false)
  const [isMute, setIsMute] = useState(false)
  const [volume, setVolume] = useState(0.5) 
  return (
    <div className="relative" onMouseOver={() => setRangeIsVisible(!isMute)} onMouseLeave={() => setRangeIsVisible(false)}>
      <button className={`transition duration-500 ${rangeIsVisible && `text-gray-900`}`} onClick={() => {
        setIsMute(!isMute)
        toggleMute()
        setRangeIsVisible(!rangeIsVisible)
      }}>
        {isMute ?
          <svg xmlns="http://www.w3.org/2000/svg" className="relative z-10" width={24} fill="none" viewBox="0 0 24 24" stroke="cyan">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" clipRule="evenodd" />
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2" />
          </svg>
        :
          <svg xmlns="http://www.w3.org/2000/svg" className="relative z-10" width={24} fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" />
          </svg>
        }
      </button>
      <AnimatePresence>
        {rangeIsVisible &&
          <motion.input
            initial={{ width: 50, opacity: 0 }}
            animate={{ width: 120, opacity: 1 }}
            exit={{ width: 50, opacity: 0 }}
            type="range"
            id="volume"
            name="volume"
            disabled={isMute}
            // waveSurfer recognize value of `0` same as `1`
            //  so we need to set some zero-ish value for silence
            min="0.01"
            max="1"
            step=".025"
            onChange={event => {
              setVolume(event.target.value)
              onVolumeChange(event)
            }}
            defaultValue={volume}
            className="absolute overflow-hidden rounded-3xl h-12 bg-gray-400 transform -rotate-90 -bottom-7.5 left-1/2 origin-left"
          />
        }
      </AnimatePresence>
    </div>
  )
}