import React, { useContext, useEffect, useRef, createRef } from 'react'
import { LayoutContext } from '../contexts/Layout'
import { ProjectsContext } from '../contexts/ProjectsContext'
import Song, { SongSkeleton } from './Song'
import { motion } from 'framer-motion'

export default function Favourites() {
  const { setLoader } = useContext(LayoutContext)
  const { AudioFavourite, errorFavourites, favourites } = useContext(ProjectsContext)
  const elRefs = useRef([])
  if (favourites && elRefs.current.length !== favourites.length) {
    elRefs.current = Array(favourites.length).fill().map((_, i) => elRefs.current[i] || createRef())
  }
  useEffect(() => {
    AudioFavourite()
  }, [AudioFavourite]) // ch
  useEffect(() => {
    setLoader(!favourites)
    // eslint-disable-next-line
  }, [favourites])
  if (errorFavourites) {
    return <span>Error</span>
  }
  if (favourites && favourites.length === 0) {
    return <div className="flex justify-center items-center h-64 font-bold">There are no favourites</div>
  }
  if (favourites && favourites.length > 0) {
    window.scrollTo(0, 0)
    return (
      <motion.div initial={{ opacity: 0 }} animate={{  opacity: 1 }} exit={{ opacity: 0 }}>
        {favourites.map((song, i) => (
          <Song key={song.ID} data={song} index={i} refs={elRefs} />
        ))}
      </motion.div>
    )
  }
  return <SongSkeleton />
}