import React from "react";
import { Route } from "react-router-dom";

import { authenticationService } from "./AuthenticationService";

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const currentUser = authenticationService.currentUserValue;
      if (!currentUser) {
        return <Component {...props} />;
      } else {
        window.close();
        return null
      }
    }}
  />
);

export default AuthRoute;
