import React, { useContext, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { LayoutContext } from '../contexts/Layout'
import { ProjectsContext } from '../contexts/ProjectsContext'
import { useMutation } from "@apollo/client"
import CreateProject from "../graphql/mutations/AddProject"

export default function NewProject() {
  const { currentSong, inputSearchIsFocus, pathname, ToastsStore } = useContext(LayoutContext)
  const { setProjects, projects, addProjectButton, setAddProjectButton } = useContext(ProjectsContext)
  const [projectName, setProjectName] = useState("")
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const [createProject, { loading }]  = useMutation(CreateProject, {
    variables: {
      name: projectName  
    },
    onCompleted(dataProject){
      if (dataProject.createProject) {
        setAddProjectButton(false)
        setProjects(projects.concat([dataProject.createProject]))
        ToastsStore.success("The project is created")
        setModalIsOpen(false)
      } else {
        ToastsStore.warning("The project title already exists.")
      }
    }
  })

  const onChange = event => {
    setProjectName(event.target.value)
  } 

  const newProject = () => {
    if (projectName) {
      createProject().then(res => {})
    } else {
      ToastsStore.warning("You must type a project name")
    }
  }
  const shouldBeCentered = addProjectButton && /^\/$/.test(pathname) && !inputSearchIsFocus && !currentSong ? true : false
  return (
    <>
      <motion.button whileTap={{ scale: 0.9 }} initial={{ scale: 0, x: shouldBeCentered ? '50%' : 0 }} animate={{ scale: 1, x: shouldBeCentered ? '50%' : 0 }} exit={{ scale: 0, x: shouldBeCentered ? '50%' : 0 }} transition={{ duration: 0.2 }} className={`fixed z-65 right-5 bottom-3 p-5 hover:text-gray-300 rounded-full border-4 border-transparent border-solid transition-all duration-200 ${!modalIsOpen && `bg-cyan-900 hover:bg-cyan-800 border-gray-700`} ${currentSong && !modalIsOpen && !addProjectButton && `bottom-32`} ${shouldBeCentered && `right-1/2 bottom-1/4`}`} onClick={() => setModalIsOpen(!modalIsOpen)}>
        {modalIsOpen ?
          <svg xmlns="http://www.w3.org/2000/svg" width={35} fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        :
          <svg xmlns="http://www.w3.org/2000/svg" width={35} fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z" />
          </svg>  
        }
      </motion.button>
      <AnimatePresence>
        {modalIsOpen &&
          <>
            <motion.div initial={{ opacity: 0, y: -100, }} animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }} exit={{ opacity: 0, y: 0, transition: { duration: 0.1, delay: 0 } }} key={1} className="fixed z-60 top-0 left-0 w-screen h-screen flex justify-center pt-40">
              <div className="flex flex-col space-y-8 text-center">
                <h2 className="text-4xl font-bold">Create new project</h2>
                {/* <p className="max-w-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p> */}
                <input type="text" onChange={onChange} className="rounded-xl text-center py-4 px-8 focus:outline-none text-gray-900" autoFocus placeholder="Project name"/>
                <motion.button whileTap={{ scale: 0.95 }} className="text-xl bg-cyan-400 hover:bg-cyan-500 text-gray-900 px-5 py-3 rounded font-bold self-center" onClick={newProject}>
                  {!loading ? 
                    `Create` 
                    : 
                      <svg className="px-5 w-16" width="29" height="29" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff">
                        <g fill="none" fillRule="evenodd">
                          <g transform="translate(1 1)" strokeWidth="2">
                            <circle strokeOpacity=".5" cx="18" cy="18" r="18"/>
                            <path stroke="black" d="M36 18c0-9.94-8.06-18-18-18">
                              <animateTransform
                                attributeName="transform"
                                type="rotate"
                                from="0 18 18"
                                to="360 18 18"
                                dur="1s"
                                repeatCount="indefinite"/>
                            </path>
                          </g>
                        </g>
                      </svg>
                    }
                </motion.button>
              </div>
            </motion.div>
            <motion.div initial={{ scale: 1, x: shouldBeCentered ? '50%' : 0 }} animate={{ scale: 60, x: shouldBeCentered ? '50%' : 0 }} exit={{ scale: 1, x: shouldBeCentered ? '50%' : 0 }} className={`fixed z-55 right-5 bottom-3 bg-gray-900 rounded-full pointer-events-none ${currentSong && !shouldBeCentered && !addProjectButton && `bottom-32`} ${shouldBeCentered && `right-1/2 bottom-1/4`}`} style={{ width: 85, height: 85}} />
          </>
        }
      </AnimatePresence>
    </>
  )
}