import { Switch, Route } from "react-router-dom";
import PrivateRoute from "./services/PrivateRoute";
import AuthRoute from "./services/AuthRoute";
import RedirectRoute from "./services/RedirectRoute";
import { ApolloProvider } from "@apollo/client";
import { Client } from "./configs/apolloClient";
import Login from "./components/Login";
import Home from "./components/Home";
import Projects from "./components/ProjectList";
import Favourites from "./components/Favourites";
import User from "./components/User";
import ProjectInfo from "./components/ProjectInfo";
import PlayList from "./components/PlayList";
import NotFound from "./components/404";
import { authenticationService } from "./services/AuthenticationService";
import RedirectedFromAdmin from "./services/RedirectedFromAdmin";

function App() {
  try {
    return (
      <ApolloProvider client={Client}>
        <Switch>
          <RedirectRoute
            exact
            path="/redirectauth/:token"
            component={RedirectedFromAdmin}
          />
          <AuthRoute exact path="/login" component={Login} />
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute exact path="/projects" component={Projects} />
          <Route exact path="/playlist/:slug" component={PlayList} />
          <PrivateRoute exact path="/project/:slug" component={ProjectInfo} />
          <PrivateRoute exact path="/favourites" component={Favourites} />
          <PrivateRoute exact path="/user" component={User} />
          <Route component={NotFound} />
        </Switch>
      </ApolloProvider>
    );
  } catch (err) {
    authenticationService.logout();
  }
}

export default App;
