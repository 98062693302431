import React, { useState, useRef, useEffect, createRef } from "react";
import WaveSurfer from "wavesurfer.js";
import _ from "lodash";
import { styled } from "twin.macro";
import { motion } from "framer-motion";
import { printDuration, normalizedPeaks } from "../services/SongsServices";
import { authenticationService } from "../services/AuthenticationService";
import axios from "axios";
import downloadFile from "large-file-downloader";

const WrapperSkeleton = styled.div`
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, #303234, transparent);
    z-index: 1;
  }
`;

export default function Song({
  data: song,
  index,
  filename,
  refs,
  currentSong,
  setCurrentSong,
}) {
  const {
    ID: id,
    AudioNombre: title,
    AudioDuracion: duration,
    slugUrl,
    Waveform,
  } = song;
  const [isDownloading, setIsDownloading] = useState(false);
  const waveformRef = refs.current[index];
  const wavesurfer = useRef([]);
  const parser = new DOMParser();

  const downloadSong = () => {
    setIsDownloading(true);
    const requestOptions = {
      method: "post",
      url: "https://musichall-api.jbcmusic.es/api/getSongPL",
      responseType: "arraybuffer",
      data: {
        fileName: filename,
      },
    };

    axios(requestOptions).then((res) => {
      let blob = new Blob([res.data], { type: "audio/mp3" });
      const downloadUrl = URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = downloadUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setIsDownloading(false);
    });
  };

  useEffect(() => {
    if (Waveform) {
      wavesurfer.current = Array(refs.current.length)
        .fill()
        .map((_, i) => wavesurfer.current[i] || createRef());
      wavesurfer.current[index] = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: "white",
        progressColor: "white",
        cursorColor: "transparent",
        barWidth: 1,
        barRadius: 1,
        responsive: false,
        height: 30,
        normalize: true,
        pixelRatio: 3,
        barGap: 0,
        hideScrollbar: true,
        interact: false,
        backend: "MediaElement",
      });

      wavesurfer.current[index].load(
        `https://musichall-api.jbcmusic.es/audio/${slugUrl}`,
        normalizedPeaks(JSON.parse(Waveform)),
        false
      );
    }
    // eslint-disable-next-line
  }, [refs]);

  const triggerAudioPlayer = () => {
    if (Waveform) {
      // Solo entrar si el audio existe
      setCurrentSong(song);
    }
  };

  return (
    <div className="relative flex justify-between items-center w-full h-12 px-10">
      {!isNaN(index) && (
        <span className="absolute left-5 transform -translate-x-1/2 text-xxs text-gray-600 text-center">
          {index + 1}
        </span>
      )}
      <div className="flex flex-1 space-x-5">
        <button
          className="text-sm font-medium tracking-wide text-left py-2 flex-1 text-gray-300 hover:text-gray-400"
          onClick={() => triggerAudioPlayer()}
        >
          {parser.parseFromString(title, "text/html").body.textContent}
          {currentSong && currentSong.ID === id && (
            <motion.span
              initial={{ scale: 0, y: "-50%" }}
              animate={{ scale: [1.5, 2, 0.5, 1.5], y: "-50%" }}
              className="absolute w-1 h-1 top-1/2 ml-3 rounded-full bg-cyan-400"
            />
          )}
        </button>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: currentSong && currentSong.ID === id ? 0.6 : 0.15 }}
        className={`absolute left-1/4 w-3/4 pr-32`}
        style={{ zIndex: 1 }}
        ref={waveformRef}
      />
      <div className="space-x-5 z-10 flex items-center">
        <span className="text-xs">
          {!isNaN(duration) ? printDuration(duration) : "--:--"}
        </span>
        {isDownloading ? (
          <svg
            className=""
            width={18}
            viewBox="0 0 38 38"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#fff"
          >
            <g fill="none" fillRule="evenodd">
              <g transform="translate(1 1)" strokeWidth="2">
                <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                <path stroke="white" d="M36 18c0-9.94-8.06-18-18-18">
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="1s"
                    repeatCount="indefinite"
                  />
                </path>
              </g>
            </g>
          </svg>
        ) : (
          <button onClick={() => downloadSong()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={18}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="hover:opacity-80"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
              />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
}

export const SongSkeleton = ({ rows }) => {
  let skeleton = [];
  _.times(rows ? rows : 20, (index) => {
    skeleton.push(
      <div
        className="flex justify-between items-center px-10 space-x-10 h-12 w-full"
        key={index}
      >
        <div className="flex items-center space-x-5 text-gray-600">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={18}
            fill="currentColor"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
            />
          </svg>
          <span
            className="h-3 rounded bg-gray-600 animate-pulse"
            style={{ width: `${Math.floor(Math.random() * 180) + 50}px` }}
          />
        </div>
        <div className="absolute left-1/4 right-32 border-b-2 border-gray-600 border-dotted animate-pulse" />
        <span className="w-10 h-3 rounded bg-gray-600 animate-pulse" />
      </div>
    );
  });
  return <WrapperSkeleton>{skeleton}</WrapperSkeleton>;
};
