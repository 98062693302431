// import icon from '../assets/icon.png'
export default function Logo() {
  return (
    <svg
      data-name="Layer 1"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 425.86 295.47"
      className="css-1vss5ax"
    >
      <path
        d="M31.75 130.75l13.63-1.87a4 4 0 014.53 3.63q1.34 17.1 7.44 23.86 6.84 7.57 18.92 7.57a28.14 28.14 0 0015.38-4.09 21 21 0 008.91-11.11q2.44-7 2.45-22.4V24.17l-25.83-.39a4 4 0 01-3.94-4v-13a4 4 0 014-4l25.76.27h23.68V125q0 22.45-5.44 34.79a38.63 38.63 0 01-17.24 18.8q-11.78 6.47-27.65 6.47-23.55 0-36.07-13.55-11.67-12.64-12-36.74a4 4 0 013.47-4.02z"
        fill="#c1c1c1"
      ></path>
      <path
        d="M121.91 178V3.05h67.14q20.5 0 32.89 5.43a42.36 42.36 0 0119.41 16.73 44.08 44.08 0 017 23.62 40.54 40.54 0 01-6.22 21.6q-6.24 10.14-18.8 16.36 16.23 4.77 25 16.24t8.73 27.1a52.25 52.25 0 01-5.31 23.37q-5.31 10.81-13.12 16.67A55 55 0 01219 179q-11.77 3-28.87 3h-64.22a4 4 0 01-4-4zm23.68-99.76h38.69q15.75 0 22.59-2.08 9-2.69 13.61-8.91t4.58-15.62A28.79 28.79 0 00220.78 36a21.61 21.61 0 00-12.2-9.28q-7.94-2.49-27.23-2.5h-35.76zm0 82.64h44.55q11.48 0 16.12-.86a38.45 38.45 0 0013.67-4.88 25.9 25.9 0 009-10 31.12 31.12 0 003.54-15.07 29.83 29.83 0 00-5.12-17.4 27 27 0 00-14.23-10.37q-9.09-3-26.18-3h-41.35z"
        fill="#c1c1c1"
      ></path>
      <path
        d="M378.74 120.25l15.8 4a4 4 0 012.86 5q-7.69 26.25-25.7 40.5-19.33 15.31-47.3 15.32-28.93 0-47.06-11.78t-27.58-34.12a121.65 121.65 0 01-9.46-48q0-28 10.68-48.77a73.26 73.26 0 0130.39-31.6A88.83 88.83 0 01324.77 0q26.85 0 45.16 13.67 16.74 12.5 24.19 34.27a4 4 0 01-2.88 5.17l-15.58 3.68a4 4 0 01-4.66-2.55q-6.12-17-16.91-25.07-11.85-8.9-29.79-8.91-20.62 0-34.48 9.89a52.24 52.24 0 00-19.5 26.55 107 107 0 00-5.61 34.36q0 22.83 6.65 39.86t20.69 25.45a58 58 0 0030.4 8.42q19.89 0 33.69-11.47 12.58-10.47 17.75-30.17a4 4 0 014.85-2.9z"
        fill="#c1c1c1"
      ></path>
      <text
        transform="translate(6.07 274.69)"
        fontSize="64"
        fill="#c1c1c1"
        fontFamily="ArialMT,Arial"
        letterSpacing=".05em"
      >
        MUSIC HAL
        <tspan x="377.3" y="0" letterSpacing=".01em">
          L
        </tspan>
      </text>
      <path
        fill="#c1c1c1"
        stroke="#c1c1c1"
        strokeMiterlimit="10"
        d="M0 203.72h425.86"
      ></path>
    </svg>
    // <img className="rounded-xl" src={icon} width="150" alt="JBC Music Hall Logo"/>
  );
}
