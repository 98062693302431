import React, { useContext } from 'react'
import { styled } from 'twin.macro'
import { LayoutContext } from '../contexts/Layout'
import { motion } from 'framer-motion'

const Wrapper = styled.div`
  top: ${props => props.inputSearchIsFocus && props.path === "/" ? `22px`: props.path !== "/" ? `-75px`: '350px'};
  ${props => props.inputSearchIsFocus ? `transform: translateX(0);` : null};
`
export default function InputSearch() {
  const { pathname, inputSearchIsFocus, setInputSearchIsFocus, filtersSidebarIsOpen, setFiltersSidebarIsOpen, activeFilters, setActiveFilters,toggleActiveFilter, setSearch, search } = useContext(LayoutContext)
  const focusInputSearch = () => setInputSearchIsFocus(true)
  const toggleFilterSidebarIsOpen = () => setFiltersSidebarIsOpen(!filtersSidebarIsOpen)
  const destroyInputSearch = () => {
    setInputSearchIsFocus(false)
    setActiveFilters([])
  }
  const ActiveFilter = ({filter}) => (
    <div className="relative flex flex-col justify-center py-1 px-2 mr-3 bg-gray-600 rounded">
      <button className="absolute top-0 right-0 p-0.5 w-full h-full flex justify-end opacity-0 hover:opacity-100 transition duration-200" onClick={() => toggleActiveFilter(filter.id, filter.type, filter.name)}>
        <svg xmlns="http://www.w3.org/2000/svg" width={10} fill="none" viewBox="0 0 24 24" stroke="cyan">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      <span className="text-xxs text-gray-300">{filter.type}</span>
      <span className="leading-none">{filter.name}</span>
    </div>
  )

  const handleChange = (e) => {
    if (e.target.value.length >= 2) {
      setSearch(e.target.value);
    } else if (e.target.value.length < 2) {
      if (search !== "") {
        setSearch("")
      }
    }
  }
  
  return (
    <Wrapper 
      className={`fixed left-16 right-0 flex items-center z-40 rounded-full bg-gray-700 transition-all duration-500 shadow-2xl ${inputSearchIsFocus && `mx-5`}`}
      style={{ margin: !inputSearchIsFocus ? '0 20vw' : null }}
      path={pathname} 
      inputSearchIsFocus={inputSearchIsFocus}
      transition={{ type: 'spring', damping: 20 }}
    >
      <button className="pl-5 pr-4 text-white" onClick={focusInputSearch}>
        <svg xmlns="http://www.w3.org/2000/svg" width={24} fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
        </svg>
      </button>
      {activeFilters.map(filter => (
        <ActiveFilter 
          key={filter.name} 
          filter={filter} />
      ))}
      <motion.button 
        className={`absolute left-1 w-12 h-12 flex justify-center items-center border-4 text-white hover:text-cyan-400 border-gray-700 border-solid bg-gray-900 rounded-full`}
        animate={{ rotate: filtersSidebarIsOpen ? 180 : 0, scale: inputSearchIsFocus ? 1 : 0 }}
        onClick={toggleFilterSidebarIsOpen}
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={24} viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
        </svg>
      </motion.button>
      <input
        className="py-3 flex-1 text-xl bg-transparent outline-none" 
        type="text" 
        placeholder="Search"
        onFocus={focusInputSearch}
        onChange={handleChange}
      />
      <motion.button whileTap={{ scale: 0.6 }} animate={{ scale: inputSearchIsFocus ? 1 : 0 }} className={`pl-5 pr-4 hover:text-cyan-400`} onClick={destroyInputSearch}>
        <svg xmlns="http://www.w3.org/2000/svg" width={24} fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </motion.button>
    </Wrapper>
  )
}