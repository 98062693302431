import React, { useState } from 'react'
import { useHistory } from "react-router-dom"
import ReactPlayer from 'react-player'
import { authenticationService } from "../services/AuthenticationService"
import { motion } from 'framer-motion'
import Spinner from '../components/Spinner'
import Logo from './Logo'
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts'
import EffectHalftone from '../components/EffectHalftone'

import video from '../assets/videoplayback.mp4'

export default function Login() {
  const history = useHistory()
  const [form, setForm] = useState({
    username: "",
    password: ""
  })
  const [loading, setLoading] = useState(false)
  
  const handleLogin = event => {
    event.preventDefault()
    setLoading(true)
    authenticationService.login(form.username, form.password, ToastsStore).then(user => {
      setLoading(false)
      if (user) {
        history.push({
          pathname: '/'
        })
      }
    })
  }
  const handleChange = event => {
    setForm({
      ...form,
      [event.target.name] : event.target.value
    })
  }

  return (
    <div className="relative w-screen h-screen flex justify-center items-center">
      <Spinner loading={loading}/>
      <div className="w-full max-w-sm mx-auto pt-10 pb-5 px-10 -mt-64 text-center rounded-2xl border-0 border-gray-600 z-10 bg-gray-800 bg-opacity-70">
        {/* <h1 className="font-bold text-3xl">JBC <span className="text-cyan-400">MUSIC HALL</span></h1> */}
        <div className="w-32 mx-auto"><Logo /></div>
        <h5 className="text-sm font-bold my-5">The <b className="text-cyan-400">fastest tool</b> to find high quality music</h5>
        <div className="flex flex-col my-5">
          <form onSubmit={handleLogin} className="flex flex-col">
            <label className="relative w-full" htmlFor="username">
              <span className="text-xxs font-semibold text-gray-400 uppercase absolute left-2 transform translate-y-2">Username</span>
              <input
                id="username"
                className="w-full px-5 pt-6 pb-2 mb-5 border rounded border-gray-600 bg-transparent outline-none text-lg font-semibold hover:bg-white hover:bg-opacity-5"
                name="username"
                type="text"
                placeholder=""
                onChange={handleChange}
                required
              />
            </label>
            <label className="relative w-full" htmlFor="pass">
              <span className="text-xxs font-semibold text-gray-400 uppercase absolute left-2 transform translate-y-2">Password</span>
              <input
                id="pass"
                className="w-full px-5 pt-6 pb-2 border rounded border-gray-600 bg-transparent outline-none text-lg font-semibold hover:bg-white hover:bg-opacity-5" 
                name="password"
                type="password"
                placeholder=""
                onChange={handleChange}
                required
              />
            </label>
            <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_CENTER} />
            <button className={`py-4 px-16 mt-5 rounded bg-cyan-400 hover:bg-cyan-500 text-gray-800 font-black ${loading && `animate-pulse pointer-events-none`}`} type="submit">
              {
                !loading ? 'LOGIN'
                : 
                <svg className="mx-auto" width="24" height="24" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff">
                  <g fill="none" fillRule="evenodd">
                    <g transform="translate(1 1)" strokeWidth="2">
                      <circle strokeOpacity=".5" cx="18" cy="18" r="18"/>
                      <path stroke="currentColor" d="M36 18c0-9.94-8.06-18-18-18">
                        <animateTransform
                          attributeName="transform"
                          type="rotate"
                          from="0 18 18"
                          to="360 18 18"
                          dur="1s"
                          repeatCount="indefinite"/>
                      </path>
                    </g>
                  </g>
                </svg>
              }
            </button>
          </form>
        </div>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 2 } }}
        className="fixed top-0 left-0 w-full h-full pointer-events-none"
      >
      <EffectHalftone>
        <ReactPlayer 
          className="absolute left-1/2 transform -translate-x-1/2 scale-110" 
          width="300%" 
          height="100%" 
          url={video} 
          playing 
          muted 
          loop /> 
      </EffectHalftone>
      </motion.div>
    </div>
  )
}