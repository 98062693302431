import React, { useContext } from 'react'
import { styled } from 'twin.macro'
import { LayoutContext } from '../contexts/Layout'
import { theme } from '../theme/config'
import { AnimatePresence, motion } from 'framer-motion'
import EffectHalftone from '../components/EffectHalftone'
import bgImage from '../assets/bg-cover-1.jpg'

const Wrapper = styled.header`
  background-color: #222426;
  ${props => props.path === "/" && !props.inputSearchIsFocus ? `
    height: 100vh;
  ` : props.path === "/login" ? `
    height: 0
  ` : /project\//.test(props.path) ? `
    height: 175px;
  ` : /projects/.test(props.path) || /user/.test(props.path) ? `
    height: 120px;
  ` : `
    height: ${theme.HEADER_HEIGHT};
  `}
`

export const WrapperStyled = styled.div`
  position: relative;
  pointer-events: none;
  z-index: 2;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(transparent, black);
    z-index: 1;
  }
`

export default function Header({ children }) {
  const { inputSearchIsFocus, pathname } = useContext(LayoutContext)
  return (
    <Wrapper className="fixed z-20 w-full left-16 top-0 flex flex-col items-center justify-center transition-all duration-500" path={pathname} inputSearchIsFocus={inputSearchIsFocus}>
      {children}
      <AnimatePresence>
        {!inputSearchIsFocus && /^\/$/.test(pathname) &&
          <motion.div key={1} initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 2 } }} exit={{ opacity: 0 }} className="fixed top-0 left-0 w-full h-full pointer-events-none">
            <EffectHalftone>
              {/* <ReactPlayer 
                className="absolute left-1/2 transform -translate-x-1/2 scale-110" 
                width="300%" 
                height="100%" 
                url={video} 
                playing 
                muted 
                loop />  */}

                <img src={bgImage} width="100%" alt="Background Cover"/>
            </EffectHalftone>
          </motion.div>
        }
      </AnimatePresence>
    </Wrapper>
  )
}