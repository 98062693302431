import { gql } from '@apollo/client'

export default gql`
  query {
    getUserLogs {
        datelog
        iplog
        agent
    }
  }
`
