import gql from "graphql-tag";

export default gql`
  mutation DeleteSongInProject($idSong: Int!, $idProject: Int!) {
    deleteSongInProject(
        input: {
            idSong: $idSong
            idProject: $idProject
        }
    )
  }
`;