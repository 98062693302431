import { gql } from '@apollo/client';

export default gql`
  query($genres: String, $themes: String, $offset: Int!, $instruments: String, $tempos: String, $voices: String, $languages: String, $search: String){
    AudiosFilterApp(input: {
        genres: $genres
        themes: $themes
        offset: $offset
        instruments: $instruments
        tempos: $tempos
        voices: $voices
        languages: $languages
        search: $search
      }) {
        ID
        AudioNombre
        AudioInterprete
        AudioCompositor
        AudioProductor_
        AudioPublisher_
        AudioISRC
        AudioISWC
        AudioPeso
        AudioDuracion
        AudioGeneros_
        AudioTempo_
        AudioAnimo_
        AudioInstrumento_
        AudioIdioma_
        AudioUploader
        AudioVoz_
        AudioUrl
        esFavorito
        slugUrl
        Waveform 
    }
    AudiosFilterNumberApp(input: {
        genres: $genres
        themes: $themes
        offset: $offset
        instruments: $instruments
        tempos: $tempos
        voices: $voices
        languages: $languages
        search: $search
      })
  }

`;
