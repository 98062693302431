import { gql } from '@apollo/client';

export default gql`
  {
    Filters {
      Genero {
        IdGenero
        Genero
      }
      Animo {
        IdAnimo
        Animo
      }
      Instrumento {
        IdInstrumento
        Instrumento
      }
      Idioma {
        IdIdioma
        Idioma
      }
      Tempo {
        IdTempo
        Tempo
      }
      Voz {
        IdVoz
        Voz
      }
    }
  }
`;
